export default ($axios) => ({
    /** Cria uma notícia
     * @param { String } titulo: Título da noticia
     * @param { String } mensagem: Mensagem da noticia
     * @param { String } imagem: Imagem da noticia em formato base64
     * @param { String } video: Vídeo da noticia em formato http
     * @param { Boolean } ativo: Status do produto
     */
    create: (data) =>
      $axios.post('/noticia/post', {
        ...data
      }),
  
    /** Editar um produto
     * @param { String } produtoId: Id do produto
     * @param { String } categoriaId: Id da categoria
     * @param { String } titulo: Título do produto
     * @param { String } subTitulo: Subtítulo do produto
     * @param { String } descricao: Descrição do produto
     * @param { String|Number } valor: Valor do produto
     * @param { String } imagem: Imagem do produto em formato base64
     * @param { String|Number } categoriaId: Id da categoria vinculada
     * @param { Boolean } ativo: Status do produto
     */
    update: (data) =>
      $axios.put('/noticia/put', {
        ...data
      }),
  
    /** Busca os produtos */
    getAll: () => $axios.get('/noticia/getAll'),
  
    getByCategoria: async (categoriaId = '') => await $axios.get(`/produto/getByCategoria?categoriaId=${categoriaId}`),
  
    /** Remove um produto */
    delete: (id = '') => $axios.put(`/noticia/delete?id=${id}`)
  })
  