export default ($axios) => ({
  /** Cria um usuário
   * @param { Object } data: "email", "senha" para criar um usuário
   */
  create: (data) =>
    $axios.post('/usuario/post', {
      ...data
    }),

  /** Atualizar um usuário
   * @param { Number } id: "usuárioId" do usuário
   * @param { Object } data: "email", "senha"
   */
  update: (data) =>
    $axios.put('/usuario/put', {
      ...data
    }),

  /** Busca os usuários */
  getAll: () => $axios.get('/usuario/getall'),

  /** Remove um usuário */
  delete: (id = '') => $axios.put(`/usuario/delete?id=${id}`)
})
