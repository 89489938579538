import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

import messagesPT from '@/locales/pt-BR'
import messagesEN from '@/locales/en-US'

const messages = {
  'pt-BR': messagesPT,
  'en-US': messagesEN
}

export const i18n = new VueI18n({
  locale: 'pt-BR', // set locale
  fallbackLocale: 'pt-BR',
  messages // set locale messages
})

const loadedLanguages = ['pt-BR'] // our default language that is preloaded

function setI18nLanguage(lang) {
  i18n.locale = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function loadLanguageAsync(lang) {
  // If the same language
  if (i18n.locale === lang) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language was already loaded
  if (loadedLanguages.includes(lang)) {
    return Promise.resolve(setI18nLanguage(lang))
  }

  // If the language hasn't been loaded yet
  return import('@/locales/pt-BR').then((messages) => {
    i18n.setLocaleMessage(lang, messages.default)
    loadedLanguages.push(lang)
    return setI18nLanguage(lang)
  })
}
