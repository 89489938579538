<template>
  <div class="maps">
    <!-- IFRAME -->
    <iframe
      :src="map"
      allowfullscreen=""
      loading="lazy"
      referrerpolicy="no-referrer-when-downgrade"
      class="maps--iframe"
    >
    </iframe>
  </div>
</template>

<script>
export default {
  name: 'Maps',
  data() {
    return {
      map:
        'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d55458.69647336373!2d-50.843391081059124!3d-29.685893228823016!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95191b609558c6c5%3A0x65c6e46340cbdb44!2sRS%20FORJARIA!5e0!3m2!1spt-BR!2sbr!4v1673045281995!5m2!1spt-BR!2sbr'
    }
  }
}
</script>

<style lang="scss" scoped>
.maps {
  position: relative;
  height: 390px;
  padding: 110px 0px;
  overflow: hidden;

  &--iframe {
    position: absolute;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
  }
}
</style>
