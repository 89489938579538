<template>
  <div class="navbar">
    <!-- DESKTOP -->
    <div class="navbar__desktop">
      <!-- BOTÕES -->
      <div class="navbar--row">
        <Heading
          v-for="(option, i) in options"
          :key="i"
          :class="{ 'navbar--option__active': value === option.name }"
          weight="bold"
          size="4"
          class="navbar--option"
        >
          <span @click="change(option.name)">{{ option.title }}</span>
        </Heading>
      </div>
      <!-- CONTEÚDOS -->
      <div class="navbar--content">
        <div v-for="(option, i) in options" :key="i" class="navbar--item">
          <slot v-if="value === option.name" :name="option.name"></slot>
        </div>
      </div>
    </div>
    <!-- MOBILE -->
    <div class="navbar__mobile">
      <!-- BOTÕES -->
      <div
        v-for="(option, i) in options"
        :key="i"
        class="navbar--accordion"
        @click="change(option.name)"
      >
        <Heading
          :class="{ 'navbar--option__active': value === option.name }"
          weight="bold"
          size="4"
          class="navbar--option navbar--option__mobile"
        >
          <span>{{ option.title }}</span>
          <font-awesome-icon
            :class="{
              'navbar--accordion--icon__active': value === option.name
            }"
            class="navbar--accordion--icon"
            icon="fa-solid fa-chevron-down"
          />
        </Heading>
        <!-- CONTEÚDOS -->
        <transition
          name="accordion"
          @enter="start"
          @after-enter="end"
          @before-leave="start"
          @after-leave="end"
        >
          <div v-if="value === option.name" class="navbar--content">
            <div class="navbar--item">
              <slot :name="option.name"></slot>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script>
import Heading from '@/components/Heading'

/** Componente padrão de navbar */
export default {
  name: 'Navbar',
  components: {
    Heading
  },
  props: {
    /** Elemento que deve ser renderizado no HTML */
    options: {
      type: Array,
      default: () => []
    },
    /** Valor selecionado */
    value: {
      type: String,
      default: ''
    }
  },
  methods: {
    /** Alterar item do navbar
     * @param {String} value: nome da opção de
     */
    change(value) {
      this.$emit('input', value)
    },

    /** Controla a trasição ao abrir o accordion */
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    /** Controla a trasição ao fechar o accordion */
    end(el) {
      el.style.height = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.navbar {
  padding: 0px;

  &__desktop {
    width: 100%;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__mobile {
    width: 100%;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &--row {
    display: flex;
    align-items: flex-end;
    gap: 64px;
    border-bottom: 1px solid $primary;
    width: 100%;
  }

  &--option {
    line-height: 31px;
    color: $brown;
    margin-bottom: 0px;
    cursor: pointer;
    display: block;
    transition: 0.2s all;
    padding-bottom: 5px;
    border-bottom: 5px solid transparent;

    &__mobile {
      padding-bottom: 0px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }

    &__active {
      color: $primary;
      border-bottom-color: $primary;
    }
  }

  &--accordion {
    margin-bottom: 20px;

    &:last-of-type {
      margin-bottom: 0px;
    }

    &--icon {
      font-size: 15px;

      &__active {
        transform: rotate(180deg);
      }
    }
  }

  &--content {
    padding: 50px 0px 50px 0px;
    width: 100%;

    @media screen and (max-width: 768px) {
      padding-top: 20px;
      padding-bottom: 0px;
    }
  }

  &--item {
    width: 100%;
    overflow-x: auto;
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
