<template>
  <div :class="{ carousel__multiple: multiple }" class="carousel">
    <div v-if="multiple" class="carousel--row">
      <Heading weight="bold" size="4" class="carousel--title">
        {{ name }}
      </Heading>
      <div class="carousel--arrows">
        <img
          :src="arrow"
          :class="{ 'carousel--arrows__disabled': !prev }"
          class="carousel--arrows__item carousel--arrows__prev"
          alt="Anterior"
          @click="handleNavigation('backward')"
        />
        <img
          :src="arrow"
          :class="{ 'carousel--arrows__disabled': !next }"
          class="carousel--arrows__item"
          alt="Próximo"
          @click="handleNavigation('forward')"
        />
      </div>
    </div>
    <Carousel
      ref="carousel"
      :adjustableHeight="adjustableHeight"
      :adjustableHeightEasing="adjustableHeightEasing"
      :autoplay="autoplay"
      :autoplayDirection="autoplayDirection"
      :autoplayHoverPause="autoplayHoverPause"
      :autoplayTimeout="autoplayTimeout"
      :centerMode="centerMode"
      :easing="easing"
      :loop="loop"
      :minSwipeDistance="minSwipeDistance"
      :mouseDrag="mouseDrag"
      :navigateTo="navigateTo"
      :navigationClickTargetSize="navigationClickTargetSize"
      :navigationEnabled="navigationEnabled"
      :navigationNextLabel="navigationNextLabel"
      :navigationPrevLabel="navigationPrevLabel"
      :paginationActiveColor="paginationActiveColor"
      :paginationColor="paginationColor"
      :paginationPosition="paginationPosition"
      :paginationEnabled="paginationEnabled"
      :paginationPadding="paginationPadding"
      :paginationSize="paginationSize"
      :perPage="perPage"
      :resistanceCoef="resistanceCoef"
      :scrollPerPage="scrollPerPage"
      :spacePadding="spacePadding"
      :spacePaddingMaxOffsetFactor="spacePaddingMaxOffsetFactor"
      :speed="speed"
      :tagName="tagName"
      :touchDrag="touchDrag"
      :class="{ 'carousel--component__multiple': multiple }"
      class="carousel--component"
    >
      <slot></slot>
    </Carousel>
  </div>
</template>

<script>
import { Carousel } from 'vue-carousel'
import Heading from '@/components/Heading'

import arrow from '@/assets/images/arrow.svg'

/** Componente padrão de carousels */
export default {
  name: 'MyCarousel',

  components: {
    Carousel,
    Heading
  },

  props: {
    /** Título do componente */
    name: {
      type: String,
      default: 'Carousel'
    },

    /** */
    adjustableHeight: {
      type: Boolean,
      default: false
    },

    /** */
    adjustableHeightEasing: {
      type: String,
      default: ''
    },

    /** */
    autoplay: {
      type: Boolean,
      default: true
    },

    /** */
    autoplayDirection: {
      type: String,
      default: 'forward'
    },

    /** */
    autoplayHoverPause: {
      type: Boolean,
      default: true
    },

    /** */
    autoplayTimeout: {
      type: Number,
      default: 2000
    },

    /** */
    centerMode: {
      type: Boolean,
      default: true
    },

    /** */
    easing: {
      type: String,
      default: 'ease'
    },

    /** */
    loop: {
      type: Boolean,
      default: false
    },

    /** */
    minSwipeDistance: {
      type: Number,
      default: 8
    },

    /** */
    mouseDrag: {
      type: Boolean,
      default: true
    },

    /** */
    navigateTo: {
      type: Number,
      default: 0
    },

    /** */
    navigationClickTargetSize: {
      type: Number,
      default: 8
    },

    /** */
    navigationEnabled: {
      type: Boolean,
      default: false
    },

    /** */
    navigationNextLabel: {
      type: String,
      default: '>'
    },

    /** */
    navigationPrevLabel: {
      type: String,
      default: '<'
    },

    /** */
    paginationActiveColor: {
      type: String,
      default: '#000'
    },

    /** */
    paginationColor: {
      type: String,
      default: '#efefef'
    },

    /** */
    paginationPosition: {
      type: String,
      default: 'bottom'
    },

    /** */
    paginationEnabled: {
      type: Boolean,
      default: false
    },

    /** */
    paginationPadding: {
      type: Number,
      default: 24
    },

    /** */
    paginationSize: {
      type: Number,
      default: 10
    },

    /** */
    perPage: {
      type: Number,
      default: 2
    },

    /** */
    resistanceCoef: {
      type: Number,
      default: 20
    },

    /** */
    scrollPerPage: {
      type: Boolean,
      default: true
    },

    /** */
    spacePadding: {
      type: Number,
      default: 0
    },

    /** */
    spacePaddingMaxOffsetFactor: {
      type: Number,
      default: 0
    },

    /** */
    speed: {
      type: Number,
      default: 500
    },

    /** */
    tagName: {
      type: String,
      default: 'slide'
    },

    /** */
    touchDrag: {
      type: Boolean,
      default: true
    },

    /** */
    multiple: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      /** Ícones das setas */
      arrow: arrow,

      /** Se o botão de avançar deve estar ativo */
      next: false,

      /** Se o botão de avançar deve estar ativo */
      prev: false
    }
  },

  mounted() {
    this.checkNavigation()
  },

  methods: {
    /** */
    handleNavigation(direction) {
      this.$refs['carousel'].handleNavigation(direction)
      this.checkNavigation()
    },

    /** Verificar botões de navegação */
    checkNavigation() {
      this.next = this.$refs['carousel'].canAdvanceForward
      this.prev = this.$refs['carousel'].canAdvanceBackward
    }
  }
}
</script>

<style lang="scss" scoped>
.carousel {
  &__multiple {
    margin: 40px 0px 60px 0px;

    @media screen and (max-width: 768px) {
      margin: 40px 0px;
    }
  }

  &--row {
    border-bottom: 2px solid $primary;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &--arrows {
    display: flex;
    align-items: center;
    gap: 20px;
    transition: 0.2s all;

    &__item {
      cursor: pointer;
      padding: 10px;
    }

    &__prev {
      transform: rotate(180deg);
    }

    &__disabled {
      filter: grayscale(1);
      opacity: 0.5;
    }
  }

  &--component {
    margin-top: 20px;

    @media screen and (max-width: 768px) {
      overflow: hidden;
    }

    &__multiple {
      ::v-deep {
        .VueCarousel-wrapper {
          overflow: visible;
        }
        .VueCarousel-inner {
          gap: 24px;

          @media screen and (max-width: 768px) {
            gap: 0px;
          }

          .slide--image {
            @media screen and (max-width: 768px) {
              width: 100%;
            }
          }
        }
        .slide__multiple {
          @media screen and (max-width: 768px) {
            max-width: unset;
          }
        }
      }
    }
  }
}
</style>
