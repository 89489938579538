<template>
    <div v-if="value" class="box-modal">
      <div class="box-modal--content">       
        <div  class="box-modal--area">
        
            <div
        :class="{ 'box-modal--area__border': product }"
        class="box-modal--area"
      >
        <span class="box-modal--close" @click="$emit('input', false)">
          <img :src="close" alt="Fechar box-modal" />
        </span>
        <Paragraph size="40" weight="bold" class="box-modal--subtitle">NOTÍCIAS</Paragraph>
        
      </div>

      <div class="box " id="news">
          <div class="box--content">
               

           
              <div v-for="(noticia, i) in news" :key="i">                
                        
                <div v-html="noticia.description"></div>
                <br>           
                <br> 
                <br> 
              </div>
            
           
          </div>
        </div>
       

        </div>
      </div>
      <!-- OVERLAY -->
      <div class="box-modal--overlay" @click="$emit('input', false)"></div>
    </div>
  </template>
  
  <script>
  
  import close from '@/assets/images/close.svg'
 
  
  /** Componente padrão de modais */
  export default {
    name: 'Modal',
    components: {            
    }, 
    props:{
        value: {
      type: Boolean,
      default: false
    }
    },
    data() {
      return {    
        news: [],   
        loadedNews: false,
        close: close,     /** Ícone de fechar */        
      }
    },
    created() {    

this.getNews()
},
    methods:{
        getNews(){
      this.loadedNews = true
      this.$news
        .getAll()
        .then((response) => {
          const newsList = response.data.map((news) => {
            return {
              id: news.noticiaId,
              title: news.titulo,
              description: news.mensagem,
              image: news.imagem,
              video: news.video,
              status: news.ativo
            }
          })
          this.news = newsList
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar as produtos.')
        })
    },
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .box-modal {
    position: fixed;
    top: 0%;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
  
    &--content {
      width: 100%;
      max-width: 1300px;
      background: $white;
      margin: 20px;
      border-radius: 4px;
      position: relative;
      max-height: 90%;
      overflow-y: auto;
    }
  
    &--overlay {
      background: rgba(0, 0, 0, 0.5);
      width: 100%;
      height: 100%;
      cursor: pointer;
      position: absolute;
      top: 0%;
      left: 0%;
      z-index: -1;
    }
  
    &--area {
      padding: 40px 115px;
  
      @media screen and (max-width: 768px) {
        padding: 20px 20px;
      }
  
      &__border {
        border-bottom: 1px solid $tertiary;
      }
  
      &__product {
        display: flex;
        align-items: flex-start;
        gap: 40px;
  
        @media screen and (max-width: 768px) {
          flex-direction: column;
          gap: 20px;
        }
      }
    }
  
    &--banner {
      height: 290px;
      overflow: hidden;
      position: relative;
  
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
        max-width: 150%;
      }
    }
  
    &--close {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      border: 1px solid $tertiary;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      position: absolute;
      top: 0px;
      right: 0px;
      margin: 25px;
  
      @media screen and (max-width: 768px) {
        margin: 20px;
      }
  
      img {
        width: 8px;
      }
    }
  
    &--subtitle {
      text-transform: uppercase;
      color: #e56000;
      border-top: 2px solid #e56000;
      display: inline-block;
      padding-top: 10px;
    }
  
    &--title {
      line-height: 42px;
      color: $fontColor;
      margin-bottom: 0px;
    }
  
    &--description {
      line-height: 28px;
  
      &__title {
        color: $primary;
      }
  
      &::v-deep {
        p {
          margin-bottom: 10px;
          display: block;
  
          &:last-of-type {
            margin-bottom: 0px;
          }
        }
  
        ul {
          margin-bottom: 10px;
          padding-left: 20px;
          list-style: disc;
        }
      }
    }
  
    &--image {
      width: 100%;
      background: $gray;
      border-radius: 4px;
      padding: 32px;
      max-width: 311px;
  
      @media screen and (max-width: 768px) {
        margin: 0px auto;
      }
  
      img {
        width: 100%;
        border-radius: 4px;
      }
    }
  
    &--product {
      width: 100%;
    }
  }
  </style>
  