<template>
  <div class="banner">
    <!-- CONEXÕES -->
    <MyCarousel
      :autoplay="true"
      :autoplayHoverPause="false"
      :autoplayTimeout="4000"
      :perPage="1"
      :loop="true"
      :paginationEnabled="true"
      :navigationEnabled="true"
      :navigationPrevLabel="`<img src='${arrowWithCircle}' />`"
      :navigationNextLabel="`<img src='${arrowWithCircle}' />`"
      class="banner--carousel"
    >
      <!-- SLIDES -->
      <Slide v-for="(item, i) in banners" :key="i">
        <div class="banner--slide">
          <!-- IMAGEM -->
          <img :src="item.image" :alt="item.title" class="banner--image" />
          <!-- CONTEÚDO DO SLIDE -->
          <div class="banner--content">
            <!-- ÁREA DO TEXTO -->
            <div class="banner--content__text">
              <!-- TÍTULO -->
              <Heading weight="x-bold" class="banner--title">{{
                item.title[$i18n.locale]
              }}</Heading>
              <!-- DESCRIÇÃO -->
              <Paragraph
                v-if="item.description"
                size="80"
                class="banner--description"
                >{{ item.description[$i18n.locale] }}</Paragraph
              >
              <!-- BOTÃO -->
              <Button :link="item.link" :target="item.target">{{
                item.buttonText[$i18n.locale]
              }}</Button>
            </div>
          </div>
        </div>
      </Slide>
    </MyCarousel>
  </div>
</template>

<script>
import MyCarousel from '@/components/MyCarousel'
import { Slide } from 'vue-carousel'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import Button from '@/components/Button'

import arrow from '@/assets/images/arrow.svg'
import arrowWithCircle from '@/assets/images/arrow-with-circle.png'

export default {
  name: 'Header',
  components: {
    MyCarousel,
    Slide,
    Heading,
    Paragraph,
    Button
  },
  props: {
    /** Banners */
    banners: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      arrow: arrow,
      arrowWithCircle: arrowWithCircle
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  position: relative;

  &--carousel {
    ::v-deep {
      .VueCarousel {
        margin-top: 0px;
      }
      .VueCarousel-pagination {
        position: absolute;
        top: 0%;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        max-width: 1080px;
        display: flex;

        .VueCarousel-dot-container {
          margin: 96px 0px !important;
          display: flex;
          gap: 8px;

          @media screen and (max-width: 768px) {
            margin: 40px 0px 20px 40px !important;
          }

          .VueCarousel-dot {
            margin: 0px !important;
            padding: 0px !important;
            background: $white !important;
            transition: 0.2s all;
            width: 8px !important;
            height: 8px !important;
            border-radius: 8px !important;

            &--active {
              width: 24px !important;
              background-color: $primary !important;
            }
          }
        }
      }
      .VueCarousel-navigation {
        .VueCarousel-navigation-prev {
          outline: none;
          left: 24px;
          transform: rotate(180deg);

          @media screen and (max-width: 768px) {
            left: 0px;
            width: 40px;
          }

          img {
            width: 100%;
          }
        }
        .VueCarousel-navigation-next {
          outline: none;
          right: 24px;
          transform: unset;

          @media screen and (max-width: 768px) {
            right: 0px;
            width: 40px;
          }

          img {
            width: 100%;
          }
        }
      }
    }
  }

  &--slide {
    position: relative;
    padding: 185px 0px 96px 0px;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      padding: 60px 40px 40px 40px;
    }
  }

  &--content {
    max-width: 1080px;
    margin: 0px auto;

    &__text {
      max-width: 648px;
    }
  }

  &--title {
    font-size: 64px;
    line-height: 64px;
    color: $white;
    margin-bottom: 32px;

    @media screen and (max-width: 768px) {
      font-size: 40px;
      line-height: 40px;
    }
  }

  &--description {
    color: $white;
    margin-bottom: 64px;
    line-height: 21px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }

  &--image {
    position: absolute;
    top: 30%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 150%;
    z-index: -1;

    @media screen and (max-width: 768px) {
      max-height: 100%;
      min-width: 100%;
      top: 0%;
      left: 0%;
      transform: unset;
      max-width: unset;
    }
  }
}
</style>
