<template>
  <div class="info-header">
    <div class="info-header--content">
      <Paragraph size="40" weight="bold">
        {{ $t('home.location.addressMin') }}
      </Paragraph>
      <Paragraph size="40" weight="bold">(51) 99879-2408</Paragraph>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

export default {
  name: 'InfoHeader',
  components: {
    Paragraph
  }
}
</script>

<style lang="scss" scoped>
.info-header {
  padding: 8px 24px;
  background: $tertiary;

  @media screen and (max-width: 768px) {
    padding: 8px 10px;
  }

  &--content {
    max-width: 1500px;
    margin: 0px auto;
    display: flex;
    gap: 70px;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }

    .paragraph {
      margin-bottom: 0px;
    }
  }
}
</style>
