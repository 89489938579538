<template>
  <div class="home">
    <AdminLayout page="Departamentos">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="5" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE DEPARTAMENTOS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="name"
            :admin="true"
            label="Nome"
            class="form--input"
          />

          <!-- CAMPO DO EMAIL -->
          <FormInput
            v-model="email"
            :admin="true"
            type="email"
            label="E-mail"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createDepartament()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <DatatableAdmin
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.data.length"
          :page="datatable.page"
          title="Departamentos cadastrados"
          termResults="resultados encontrados"
          class="datatable"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR DEPARTAMENTO -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER DEPARTAMENTOS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-trash-can"
                @click="dialogCorpus(slotProps.row.id)"
              />
            </div>
          </template>
        </DatatableAdmin>

        <!-- EDITAR DEPARTAMENTOS -->
        <ModalAdmin
          v-model="departament.edit"
          title="EDITAR DEPARTAMENTO"
          class="modal-edit__edit"
        >
          <!-- NOME DO DEPARTAMENTO -->
          <FormInput
            v-model="departament.name"
            :admin="true"
            :border="true"
            label="Nome do Departamento"
            class="modal-edit--input"
          />

          <!-- E-MAIL DO DEPARTAMENTO -->
          <FormInput
            v-model="departament.email"
            :admin="true"
            :border="true"
            label="E-mail do Departamento"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveDepartament()"
            >
              GRAVAR
            </Button>
          </div>
        </ModalAdmin>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE DEPARTAMENTOS -->
        <ModalAdmin
          v-model="departament.delete"
          title="REMOVER DEPARTAMENTOS"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o departamento:
            <strong>{{ departament.name }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalDepartament()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteDepartament()"
            >
              DELETAR
            </Button>
          </div>
        </ModalAdmin>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import DatatableAdmin from '@/components/DatatableAdmin'
import ModalAdmin from '@/components/ModalAdmin'

export default {
  name: 'Departaments',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    DatatableAdmin,
    ModalAdmin
  },

  data() {
    return {
      /** Nome do departamento */
      name: null,

      /** E-mail do departamento */
      email: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'name', alias: 'Departamento' },
          { name: 'email', alias: 'E-mail' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: []
      },

      /** Visibilidade da modal */
      modalVisibility: false,

      /** Dados da modal */
      modal: {
        id: null,
        title: null,
        email: null,
        products: []
      },

      /** Departamento a ser editado */
      departament: {
        id: null /** ID do departamento */,
        name: null /** Nome do departamento */,
        email: null /** E-mail do departamento */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Buscar as departamentos */
    this.getDepartaments()
  },

  methods: {
    /** Buscar as departamentos */
    getDepartaments() {
      this.$departaments
        .getAll()
        .then((response) => {
          const departaments = response.data.map((departament) => {
            return {
              id: departament.departamentoId,
              name: departament.descricao,
              email: departament.email
            }
          })
          this.datatable.data = departaments
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar as departamentos.')
        })
    },

    /** Criar departamento */
    createDepartament() {
      this.$departaments
        .create({ descricao: this.name, email: this.email })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Departamento criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o departamento!')
        })
        .finally(() => {
          /** Limpar os campo */
          this.name = null
          this.email = null
          /** Recarregar a lista */
          this.getDepartaments()
        })
    },

    /** Exibir os arquivos do departamento
     * @param {Number} id: Id do departamento
     */
    showWordlists(id) {
      const departament = this.datatable.data.find((item) => item.id === id)
      if (departament) {
        this.modal.id = departament.id
        this.modal.title = departament.name
        this.modal.email = departament.email
        this.modal.files = departament.wordlistsData
        this.modalVisibility = true
      } else {
        this.$toast.warning('Houve um erro ao encontrar o departamento.')
        this.closeModalDepartament()
      }
    },

    /** Exibir a modal de edição do departamento
     * @param {Number} id: Id do departamento
     */
    edit(id) {
      /** Localizar o departamento na listagem */
      const departament = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do departamento e abrir a modal */
      if (departament) {
        this.departament.edit = true
        this.departament.id = departament.id
        this.departament.name = departament.name
        this.departament.email = departament.email
      } else {
        this.$toast.warning('Houve um erro ao encontrar o departamento.')
        this.closeModalDepartament()
      }
    },

    /** Confirmar se deseja remover o departamento
     * @param {Number} id: Id do departamento
     */
    dialogCorpus(id) {
      /** Localizar o departamento na listagem */
      const departament = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do departamento e abrir a modal de confirmação */
      if (departament) {
        this.departament.delete = true
        this.departament.id = departament.id
        this.departament.name = departament.name
        this.departament.email = departament.email
      } else {
        this.$toast.warning('Houve um erro ao encontrar o departamento.')
        this.closeModalDepartament()
      }
    },

    /** Fechar a modal de edição de departamento */
    closeModalDepartament() {
      this.modal.id = null
      this.modal.title = null
      this.modal.email = null
      this.modal.files = []
      this.departament.edit = false
      this.departament.delete = false
      this.departament.id = null
      this.departament.name = null
      this.departament.email = null
      this.modalVisibility = false
    },

    /** Buscar as departamentos */
    saveDepartament() {
      this.$departaments
        .update({
          departamentoId: this.departament.id,
          descricao: this.departament.name,
          email: this.departament.email,
          ativo: true
        })
        .then(() => {
          this.$toast.success('Departamento atualizada com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o departamento.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalDepartament()
          /** Recarregar a listagem */
          this.getDepartaments()
        })
    },

    /** Remover o departamento */
    deleteDepartament() {
      this.$departaments
        .delete(this.departament.id)
        .then(() => {
          this.$toast.success('Departamento removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o departamento.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalDepartament()

          setTimeout(() => {
            /** Recarregar a listagem */
            this.getDepartaments()
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(3),
    tr td:nth-child(3) {
      width: 120px;
      text-align: center;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }
}

/** MODAL DE EDIÇÃO DO DEPARTAMENTOS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
