<template>
  <footer class="footer">
    <div class="footer--container" id="representatives">
      <Heading size="4" weight="bold" class="footer--title">{{
        $t('home.footer.representatives')
      }}</Heading>

      <div class="footer--representant">
        <div
          v-for="(representative, i) in representatives"
          :key="`representante-${i}`"
          class="footer--representant__item"
        >
          <Paragraph size="60" weight="bold" class="footer--paragraph">
            {{ representative.nome }}</Paragraph
          >
          <ul>
            <li>
              <Paragraph
                size="40"
                weight="medium"
                class="footer--paragraph footer--paragraph__listItem"
                >{{ representative.uf }}</Paragraph
              >
            </li>
            <li>
              <Paragraph
                size="40"
                weight="medium"
                class="footer--paragraph footer--paragraph__listItem"
                >{{ representative.email }}</Paragraph
              >
            </li>
            <li>
              <Paragraph
                size="40"
                weight="medium"
                class="footer--paragraph footer--paragraph__listItem"
              >
                <a
                  :href="
                    `https://api.whatsapp.com/send?phone=55${formatNumber(
                      representative.telefone
                    )}&text=RS%20FORJARIA`
                  "
                  class="footer--paragraph__phone"
                  target="_blank"
                >
                  {{ representative.telefone }}
                  <font-awesome-icon
                    class="footer--whatsapp-icon"
                    icon="fa-brands fa-whatsapp"
                  />
                </a>
              </Paragraph>
            </li>
          </ul>
        </div>
      </div>

      <SeparatedLine
        :top="60"
        :left="0"
        :right="0"
        class="footer--separated-line"
      />

      <div class="footer--final-description">
        <Paragraph size="10" weight="semi-bold" class="footer--paragraph"
          >2022 © {{ $t('home.footer.rights') }} -
          {{ $t('home.title') }}</Paragraph
        >
        <!-- TROCA DE IDIOMAS -->
        <LanguageFlags />
      </div>
    </div>
  </footer>
</template>

<script>
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import SeparatedLine from '@/components/SeparatedLine'
import LanguageFlags from '@/components/LanguageFlags'

export default {
  name: 'Footer',
  components: {
    Heading,
    Paragraph,
    SeparatedLine,
    LanguageFlags
  },

  data() {
    return {
      /** Representantes */
      representatives: []
    }
  },

  created() {
    /** Buscar os representantes */
    this.getRepresentatives()
  },

  methods: {
    /** Buscar os representantes */
    getRepresentatives() {
      this.$representatives
        .getAll()
        .then((response) => {
          const results = response.data
          this.representatives = results
        })
        .catch(function() {
          this.$toast.warning(this.$t('home.error.get-representatives'))
        })
    },

    /**
     * Remover caracteres especiais
     * @param {String} number: número de celular com parenteses
     */
    formatNumber(number) {
      return number.replace(/[^0-9]/gi, '')
    }
  }
}
</script>

<style lang="scss" scoped>
.footer {
  background: $secondary;
  padding: 64px 24px 24px 24px;

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  &--representant {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }

    &__item {
      flex: 1;
    }
  }

  &--container {
    max-width: 1360px;
    margin: 0px auto;
  }

  &--title {
    color: $tertiary;
    margin-bottom: 32px;
  }

  &--paragraph {
    color: $white;
    line-height: 18px;

    &__listItem {
      line-height: 15px;
      margin-bottom: 8px !important;
    }

    &__phone {
      display: flex;
      align-items: center;
      gap: 5px;
    }
  }

  &--whatsapp-icon {
    cursor: pointer;
    font-size: paragraph-size('paragraph-60');
  }

  &--separated-line {
    ::v-deep hr {
      border-color: $white;
    }
  }

  &--final-description {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
