import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { i18n } from '@/plugins/i18n'
import '@/assets/scss/global.scss'
import store from './store'

import Services from '@/plugins/axios'
Vue.use(Services)

import VueMask from 'v-mask'
Vue.use(VueMask)

Vue.config.productionTip = false

import VueCarousel from 'vue-carousel'
Vue.use(VueCarousel)

import vSelect from 'vue-select'
Vue.component('v-select', vSelect)

/* add fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'

/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

/* add some free styles */
import {
  faBars,
  faTimes,
  faChevronUp,
  faChevronDown,
  faCircleCheck,
  faCircleUser,
  faDownload,
  faSortDown,
  faKey,
  faPenToSquare,
  faRightFromBracket,
  faTrashCan,
  faCircleXmark,
  faFile
} from '@fortawesome/free-solid-svg-icons'

import { faWhatsapp } from '@fortawesome/free-brands-svg-icons'

/* add each imported icon to the library */
library.add(
  faBars,
  faTimes,
  faChevronUp,
  faChevronDown,
  faCircleCheck,
  faCircleUser,
  faDownload,
  faSortDown,
  faKey,
  faPenToSquare,
  faRightFromBracket,
  faTrashCan,
  faCircleXmark,
  faFile,
  faWhatsapp
)

/* add font awesome icon component */
Vue.component('font-awesome-icon', FontAwesomeIcon)

import Toast from 'vue-toastification'
import 'vue-toastification/dist/index.css'

const options = {
  position: 'bottom-left',
  timeout: 3000,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: false,
  closeButton: 'button',
  icon: true,
  rtl: false
}

Vue.use(Toast, options)

new Vue({
  i18n,
  router,
  store,
  render: (h) => h(App)
}).$mount('#app')
