<template>
  <div class="textarea">
    <div class="textarea--body">
      <!-- LABEL -->
      <label
        v-if="label"
        :for="`textarea-${id}`"
        :class="{ 'textarea--label__filled': !isEmpty() || focus }"
        class="textarea--label"
      >
        <Paragraph :size="focus || !isEmpty() ? 40 : 80" weight="semi-bold">{{
          label
        }}</Paragraph>
      </label>
      <!-- ENTRADA DE TEXTO -->
      <textarea
        :value="value"
        :rows="rows"
        :maxlength="maxCaracteres"
        :id="`textarea-${id}`"
        :class="{ 'textarea--field__admin': admin }"
        class="textarea--field"
        @input="$emit('input', $event.target.value)"
        @focus="
          $emit('focus', $event)
          focus = true
        "
        @blur="
          $emit('blur', $event)
          focus = false
        "
      >
      </textarea>
    </div>
    <!-- CONTAGEM DE CARACTERES -->
    <div class="textarea--max-length">
      <Paragraph size="40" weight="semi-bold" class="textarea--paragraph">
        {{ $t('home.contact.max') }} {{ maxCaracteres }}
        {{ $t('home.contact.characters') }}
      </Paragraph>
      <Paragraph size="40" weight="semi-bold" class="textarea--paragraph">
        {{ value.length ?? 0 }}/{{ maxCaracteres }}
      </Paragraph>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de textarea */
export default {
  name: 'Textarea',
  components: {
    Paragraph
  },
  props: {
    type: {
      type: String,
      default: 'text'
    },
    /** Label do campo */
    label: {
      type: String,
      default: 'Insira o conteúdo'
    },
    /** Alerta/Aviso */
    alert: {
      type: String,
      default: ''
    },
    /** Nome do campo */
    name: {
      type: String,
      default: ''
    },
    /** Valor do campo */
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },
    /** Número máximo de caracteres */
    maxCaracteres: {
      type: Number,
      default: 200
    },
    /** Número de linhas */
    rows: {
      type: Number,
      default: 5
    },

    /** Se é admin */
    admin: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      id: this._uid /** Id único do componente */,
      focus: false /** Foco no componente */
    }
  },
  methods: {
    isEmpty() {
      return this.value === '' || this.value === null
    }
  }
}
</script>

<style lang="scss" scoped>
.textarea {
  width: 100%;
  margin-bottom: 30px;

  &--body {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: $white;
    border-radius: 5px;
    margin-bottom: 2px;
  }

  &--label {
    position: absolute;
    top: 16px;
    padding: 0px 16px;
    transition: 0.2s all;

    &__filled {
      top: 11px;
      transform: translateY(0%);
    }
  }

  &--field {
    background: $white;
    color: $fontColor;
    width: 100%;
    border: 0;
    padding: 30px 16px 10px 16px;
    outline: none;

    &__admin {
      border-radius: 5px;
      padding: 25px 16px 10px 16px;
      border: 1px solid $gray;
    }
  }

  &--paragraph {
    color: $fontColorSecondary;
    margin: 0px 16px;
    line-height: 18px;
  }

  &--max-length {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
</style>
