<template>
  <div class="home">
    <AdminLayout page="Usuários">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="5" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE USUÁRIOS -->
        <div class="form">
          <!-- CAMPO DO E-MAIL -->
          <FormInput
            v-model="email"
            :admin="true"
            type="email"
            label="E-mail"
            class="form--input"
          />

          <!-- CAMPO DO SENHA -->
          <FormInput
            v-model="password"
            :admin="true"
            type="password"
            label="Senha"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button :radius="false" class="form--button" @click="createUser()">
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <DatatableAdmin
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.data.length"
          :page="datatable.page"
          title="Usuários cadastrados"
          termResults="resultados encontrados"
          class="datatable"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR USUÁRIOS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER USUÁRIOS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-trash-can"
                @click="dialogUsers(slotProps.row.id)"
              />
            </div>
          </template>
        </DatatableAdmin>

        <!-- EDITAR USUÁRIOS -->
        <ModalAdmin
          v-model="user.edit"
          title="EDITAR USUÁRIO"
          class="modal-edit__edit"
        >
          <!-- E-MAIL DO USUÁRIO -->
          <FormInput
            v-model="user.email"
            :admin="true"
            :border="true"
            type="email"
            label="E-mail"
            class="modal-edit--input"
          />

          <!-- CAMPO DO SENHA -->
          <FormInput
            v-model="user.password"
            :admin="true"
            type="password"
            label="Preencha se deseja alterar a senha"
            class="form--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveUser()"
            >
              GRAVAR
            </Button>
          </div>
        </ModalAdmin>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE USUÁRIO -->
        <ModalAdmin
          v-model="user.delete"
          title="REMOVER USUÁRIO"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o usuário:
            <strong>{{ user.email }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalUser()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteUser()"
            >
              DELETAR
            </Button>
          </div>
        </ModalAdmin>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import DatatableAdmin from '@/components/DatatableAdmin'
import ModalAdmin from '@/components/ModalAdmin'

export default {
  name: 'Users',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    DatatableAdmin,
    ModalAdmin
  },

  data() {
    return {
      /** E-mail do usuário */
      email: null,

      /** Senha do usuário */
      password: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'email', alias: 'Usuário' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: []
      },

      /** Categoria a ser editado */
      user: {
        id: null /** ID do usuário */,
        email: null /** E-mail do usuário */,
        pasword: null /** Senha do usuário */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Buscar os usuários */
    this.getUsers()
  },

  methods: {
    /** Buscar as usuários */
    getUsers() {
      this.$users
        .getAll()
        .then((response) => {
          const users = response.data.map((user) => {
            return {
              id: user.usuarioId,
              email: user.email
            }
          })
          this.datatable.data = users
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os usuários.')
        })
    },

    /** Criar usuário */
    createUser() {
      this.$users
        .create({
          email: this.email,
          senha: this.password
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Usuário criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o usuário!')
        })
        .finally(() => {
          /** Limpar os campos do usuário */
          this.email = null
          this.password = null
          /** Recarregar a lista */
          this.getUsers()
        })
    },

    /** Exibir a modal de edição do usuário
     * @param {Number} id: Id do usuário
     */
    edit(id) {
      /** Localizar o usuário na listagem */
      const user = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do usuário e abrir a modal */
      if (user) {
        this.user.edit = true
        this.user.id = user.id
        this.user.email = user.email
      } else {
        this.$toast.warning('Houve um erro ao encontrar o usuário.')
        this.closeModalUser()
      }
    },

    /** Confirmar se deseja remover o usuário
     * @param {Number} id: Id do usuário
     */
    dialogUsers(id) {
      /** Localizar o usuário na listagem */
      const user = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do usuário e abrir a modal de confirmação */
      if (user) {
        this.user.delete = true
        this.user.id = user.id
        this.user.email = user.email
      } else {
        this.$toast.warning('Houve um erro ao encontrar o usuário.')
        this.closeModalUser()
      }
    },

    /** Fechar a modal de edição de usuário */
    closeModalUser() {
      this.user.edit = false
      this.user.delete = false
      this.user.id = null
      this.user.email = null
    },

    /** Buscar os usuários */
    saveUser() {
      /** Pegar os dados de edição do usuário */
      const data = {
        usuarioId: this.user.id,
        email: this.user.email,
        ativo: true
      }

      /** Se a senha precisa ser redefinida */
      if (this.user.password !== null && this.user.password !== '') {
        data.senha = this.user.password
      }

      /** Atualizar o usuário */
      this.$users
        .update(data)
        .then(() => {
          this.$toast.success('Usuário atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o usuário.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()
          /** Recarregar a listagem */
          this.getUsers()
        })
    },

    /** Remover o usuário */
    deleteUser() {
      this.$users
        .delete(this.user.id)
        .then(() => {
          this.$toast.success('Usuário removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o usuário.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalUser()

          setTimeout(() => {
            /** Recarregar a listagem */
            this.getUsers()
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(2),
    tr td:nth-child(2) {
      width: 120px;
      text-align: center;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }
}

/** MODAL DE EDIÇÃO DO CATEGORIAS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
