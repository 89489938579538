export default ($axios) => ({
  /** Cria um departamento
   * @param { Object } data: "Descricao" para criar um departamento
   */
  create: (data) =>
    $axios.post('/departamento/post', {
      ...data
    }),

  /** Atualizar um departamento
   * @param { Number } id: "departamentoId" da departamento
   * @param { Object } data: "Descricao"
   */
  update: (data) =>
    $axios.put('/departamento/put', {
      ...data
    }),

  /** Busca as departamentos */
  getAll: () => $axios.get('/departamento/getall'),

  /** Remove um departamento */
  delete: (id = '') => $axios.put(`/departamento/delete?id=${id}`)
})
