/** Dicionário de termos da tela inicial */

export const home = {
  title: 'RS FORJARIA',
  description1: `Fundada em 2001 na cidade de Taquara, iniciou suas atividades
  com apenas 2 funcionários, e atualmente conta com cerca de 40
  colaboradores.`,
  whoWeAre: 'Quem somos',
  representatives: 'Representantes',
  knowMore: 'Saiba mais',
  aboutUs:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.',
  description2: `Nossa expansão é fruto do empenho do nosso time, para conquistar
  a confiança dos nossos clientes e fornecedores.`,
  description3: 'Continuaremos sempre em busca do aperfeiçoamento.',
  labelTitle1: 'Prédio próprio',
  labelDescription1: 'Área de produção de 20.000m²',
  labelTitle2: 'Forjaria com matrizaria',
  labelDescription2: 'Maior agilidade no atendimento',
  labelTitle3: 'Confiança',
  labelDescription3: 'Profissionalismo do início ao fim do serviço',

  /** BOTÕES DO MENU */
  menu: {
    home: 'HOME',
    whoWeAre: 'QUEM SOMOS',
    representatives: 'REPRESENTANTES',
    news: 'NOTÍCIAS',
    products: 'PRODUTOS',
        contact: 'CONTATO',
        partners: 'PARCEIROS',
  },

  navbar: {
    principles: 'Nossos princípios',
    machinery: 'Maquinário',
    certification: 'Certificação'
  },

  /** Notícias */
  news:{
    description: 'Fique atento às novidades!'
  },

    /** Parceiros */
    partners:{
      description: 'Empresas parceiras.'
    },

  /** Produtos */
  products: {
    description: 'O que estamos fazendo de novo para você, acompanhe abaixo',
    detais: 'Detalhes do produto'
  },

  /** Maquinário */
  machinery: {
    description: 'Descrição',
    // brand: 'Marca',
    // capacity: 'Capacidade'
  },

  /** Contato */
  contact: {
    contactUs: 'Fale conosco',
    budget: 'Solicite orçamento',
    name: 'Nome',
    email: 'E-mail',
    alertEmail: 'Enviaremos a resposta para este e-mail',
    department: 'Departamento',
    subject: 'Assunto',
    comments: 'Observações',
    send: 'Enviar',
    representative: 'Representante',
    product: 'Produto',
    amount: 'Qtd',
    max: 'Máximo',
    characters: 'caracteres',
    feedbackTitle: 'Mensagem enviada com sucesso!',
    feedbackMessage:
      'Agradecemos sua mensagem. Em breve nossa equipe entrará em contato.'
  },

  /** Detalhes de contato */
  details: {
    commercial: 'Comercial',
    financial: 'Financeiro',
    phone: 'Telefone',
    wpp: 'Whatsapp'
  },

  /** Localização */
  location: {
    title: 'Localização',
    address:
      'Highway RS 020, 8672 - Parada 125, PO Box 22 Santa Cruz da Concórdia – Taquara/RS CEP 95600-000',
    addressMin: 'Rod RS 020, 8672 - Parada 125, Santa Cruz da Concordia – Taquara/RS'
  },

  /** Rodapé */
  footer: {
    representatives: 'Representantes',
    rights: 'TODOS OS DIREITOS RESERVADOS'
  },

  /** Erros de endpoint */
  error: {
    'get-categories': 'Houve um erro ao apresentar as categorias.',
    'get-departaments': 'Houve um erro ao apresentar os departamentos.',
    'get-representatives': 'Houve um erro ao apresentar os representantes.',
    'get-products': 'Houve um erro ao apresentar os produtos.',
    'contact-email': 'Houve um erro ao enviar o e-mail de contato!',
    'budget-email': 'Houve um erro ao enviar o e-mail de orçamento!'
  }
}
