<template>
  <component
    :is="element"
    :class="`heading heading__size-${size} heading__weight-${weight}`"
  >
    <slot> Título </slot>
  </component>
</template>

<script>
/** Componente padrão de títulos */
export default {
  name: 'Heading',
  props: {
    /** Elemento que deve ser renderizado no HTML */
    element: {
      type: String,
      default: 'h2'
    },
    /** Tamanho */
    size: {
      type: [String, Number],
      default: 2
    },
    /** Negrito */
    weight: {
      type: String,
      default: 'normal'
    }
  }
}
</script>

<style lang="scss" scoped>
.heading {
  margin-bottom: 10px;
  color: $orange;

  &__size-1 {
    font-size: heading-size('heading-100');
    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      font-size: heading-size('heading-80');
    }
  }
  &__size-2 {
    font-size: heading-size('heading-80');
    font-weight: font-weight('weight-bold');
  }
  &__size-3 {
    font-size: heading-size('heading-60');
    font-weight: font-weight('weight-bold');
  }
  &__size-4 {
    font-size: heading-size('heading-40');
  }
  &__size-5 {
    font-size: heading-size('heading-20');
  }
  &__size-6 {
    font-size: heading-size('heading-10');
  }

  &__weight-x-bold {
    font-weight: font-weight('weight-x-bold');
  }
  &__weight-bolder {
    font-weight: font-weight('weight-bolder');
  }
  &__weight-bold {
    font-weight: font-weight('weight-bold');
  }
  &__weight-semi-bold {
    font-weight: font-weight('weight-semi-bold');
  }
  &__weight-medium {
    font-weight: font-weight('weight-medium');
  }
  &__weight-normal {
    font-weight: font-weight('weight-normal');
  }
  &__weight-light {
    font-weight: font-weight('weight-light');
  }
  &__weight-x-light {
    font-weight: font-weight('weight-x-light');
  }
  &__weight-thin {
    font-weight: font-weight('weight-thin');
  }
}
</style>
