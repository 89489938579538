<template>
  <div class="datatable">
    <div class="datatable--info">
      <div>
        <!-- TÍTULO -->
        <Heading v-if="title" size="5" weight="bold" class="title">
          {{ title }}
        </Heading>

        <!-- INFORMAÇÕES -->
        <Paragraph size="40" weight="normal" class="datatable--letters">
          <strong>{{ numberElements }}</strong> {{ termResults }}
        </Paragraph>
      </div>

      <div v-if="changeVisibility" class="datatable--info__grids">
        <!-- GRID MAIOR -->
        <font-awesome-icon
          :class="{ 'datatable--grid__active': statusTable === 'upper' }"
          class="datatable--grid"
          icon="fa-solid fa-table-cells"
          @click="statusTable = 'upper'"
        />

        <!-- GRID MENOR -->
        <font-awesome-icon
          :class="{ 'datatable--grid__active': statusTable === 'lower' }"
          class="datatable--grid"
          icon="fa-solid fa-table-cells-large"
          @click="statusTable = 'lower'"
        />
      </div>
    </div>

    <!-- TABELA -->
    <table
      :class="{ 'datatable--table__overflow': overflow }"
      class="datatable--table"
      @scroll="onScroll"
    >
      <!-- COLUNAS -->
      <thead class="datatable--thead">
        <tr>
          <th v-for="item in cols" :key="item.name">
            <Paragraph size="40" weight="medium" class="datatable--heading">
              {{ item.alias }}
              <!-- ORDENADOR -->
              <font-awesome-icon
                v-if="item.order && rows.length"
                :class="{
                  'datatable--order__desc':
                    order === item.name && direction === 'desc'
                }"
                class="datatable--order"
                icon="fa-solid fa-circle-arrow-down"
                @click="orderCallback(item.name)"
              />
            </Paragraph>
          </th>
        </tr>
      </thead>
      <tbody>
        <!-- LINHAS -->
        <tr v-for="row in rows" :key="row.id" class="datatable--row">
          <td
            v-for="col in cols"
            :key="col.name"
            :style="col.style"
            :class="{ 'datatable--td__revoked': row.situation === false }"
            class="datatable--td"
          >
            <slot :name="col.name" :value="row[col.name]" :row="row">
              <Paragraph :style="col.style" size="60" weight="normal">
                <span v-html="row[col.name]"></span>
              </Paragraph>
            </slot>
          </td>
        </tr>
        <!-- SE NÃO HOUVER NENHUM DADO -->
        <tr v-if="rows && rows.length === 0" class="datatable--row">
          <td :colspan="cols.length" class="datatable--td">
            <Paragraph size="60" weight="normal">{{ emptyMessage }}</Paragraph>
          </td>
        </tr>
      </tbody>
    </table>

    <!-- NÚMERO DE ELEMENTOS PESQUISADOS -->
    <Paragraph
      v-if="loadedResults"
      size="40"
      weight="normal"
      class="datatable--letters"
    >
      <strong>{{ rows.length }}</strong
      >/{{ numberElements }} {{ termLoaded }}
    </Paragraph>
  </div>
</template>

<script>
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'

/** Componente padrão de tabelas */
export default {
  name: 'DatatableAdmin',

  components: {
    Heading,
    Paragraph
  },

  props: {
    /** Colunas maiores
     * @example
     * {
     *  name: 'column',
     *  alias: 'Coluna'
     * }
     */
    colsUpper: {
      type: Array,
      default: () => []
    },

    /** Colunas menores */
    colsLower: {
      type: Array,
      default: () => []
    },

    /** Linhas
     * @example
     * {
     *  column1: 'Linha',
     *  column2: 'Linha',
     *  column3: 'Linha',
     * }
     */
    rows: {
      type: Array,
      default: () => []
    },

    /** Título da tabela */
    title: {
      type: String,
      default: null
    },

    /** Se deve mudar o status de visualização da tabela (upper to lower) */
    changeVisibility: {
      type: Boolean,
      default: false
    },

    /** Se deverá ter um overscroll */
    overflow: {
      type: Boolean,
      default: false
    },

    /** Status da tabela, se está com os dados maoires ou menores */
    statusTableInitial: {
      type: String,
      default: 'upper'
    },

    /** Palavra-chave */
    query: {
      type: String,
      default: null
    },

    /** Número de elementos */
    numberElements: {
      type: Number,
      default: 0
    },

    /** Número de páginas */
    numberPages: {
      type: Number,
      default: 0
    },

    /** Se a tabela estiver vazia */
    emptyMessage: {
      type: String,
      default: 'Não há dados para serem apresentados.'
    },

    /** Termo para trazer os resultados encontrados */
    termResults: {
      type: String,
      default: 'resultados encontrados'
    },

    /** Se deve apresentar o número de itens carregados */
    loadedResults: {
      type: Boolean,
      default: false
    },

    /** Termo para trazer os itens carregados */
    termLoaded: {
      type: String,
      default: 'itens carregados'
    }
  },

  data() {
    return {
      /** Se a tabela é maior ou menor (grid) */
      statusTable: null,

      /** Ordenador */
      order: null,

      /** Direção */
      direction: 'asc',

      /** Termo pesquisado */
      search: null,

      /** Termo filtrado */
      filter: null
    }
  },

  computed: {
    cols() {
      return this.statusTable === 'upper' ? this.colsUpper : this.colsLower
    }
  },

  mounted() {
    /** Pegar o status inicial da tabela */
    this.statusTable = this.statusTableInitial
  },

  methods: {
    /** Ordenação das colunas
     * @param {String} name: Identificador do item da coluna para ordenar
     */
    orderCallback(name) {
      this.order = name
      this.direction = this.direction === 'desc' ? 'asc' : 'desc'

      this.$emit('changeOrdering', {
        order: this.order,
        direction: this.direction
      })
    },

    /** Detectar quando chegar no final do scroll da tabela */
    onScroll({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight >= scrollHeight && this.rows.length) {
        this.$emit('endScroll')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.datatable {
  margin-bottom: 40px;

  &--table {
    background: $white;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    border-collapse: collapse;
    margin-bottom: 10px;
    position: relative;

    &__overflow {
      display: block;
      overflow: auto;
      max-height: calc(100vh - 100px);

      th {
        width: auto;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1;
      }
    }

    @media screen and (max-width: 768px) {
      display: block;
      overflow: auto;
    }
  }

  &--info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .paragraph {
      margin: 0px;
    }

    &__grids {
      display: flex;
      gap: 10px;
    }
  }

  &--search {
    display: flex;
    align-items: center;
    gap: 10px;

    .form-input,
    .form-select {
      min-width: 200px;
      margin-bottom: 0px;
    }
  }

  &--grid {
    cursor: pointer;
    color: $secondary;
    transition: 0.2 all;

    &__active {
      color: $primary;
    }
  }

  &--thead {
    th {
      padding: 10px;
      border-right: 1px solid $gray;
      cursor: pointer;
      background: $primary;

      @media screen and (max-width: 768px) {
        width: 200px;
      }

      &:first-of-type {
        border-radius: 10px 0px 0px 0px;
      }

      &:last-of-type {
        border-radius: 0px 10px 0px 0px;
      }
    }
  }

  &--order {
    position: absolute;
    font-size: paragraph-size('paragraph-80');
    color: $white;
    top: 50%;
    right: 5px;
    transform: translateY(-50%);

    &__desc {
      transform: translateY(-50%) rotate(-180deg);
    }
  }

  &--heading {
    color: $white;
    line-height: 24px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 0px;
    position: relative;
  }

  &--row {
    &:nth-child(odd) {
      background-color: $gray;
    }
  }

  &--td {
    padding: 12px;
    border-right: 1px solid $gray;
    vertical-align: middle;

    @media screen and (max-width: 768px) {
      vertical-align: baseline;
    }

    &:last-of-type {
      border-right: 0;
    }

    .paragraph {
      line-height: 20px;
      word-break: break-word;
    }

    &__revoked {
      p {
        opacity: 0.7;
      }
    }
  }

  &--description {
    line-height: 24px;
  }
}
</style>
