<template>
    <div class="home">
      <BaseLayout>
        <template #content>
          <!-- BANNERS -->
          <Banner :banners="banners"></Banner>
  
          <!-- INFORMAÇÃO -->         
            <div class="info-box--desc info-box--desc__gray">
              <div class="info-box--container" >
                <Heading
                  size="1"
                  weight="x-bold"
                  class="box--content"
                  >Notícias</Heading >             
               
              
             

           
          </div>
          <div v-if="datatable.data.length > 0">
              <div v-for="item in datatable.data" :key="item.noticiaId" >             
                <div v-html="item.description"></div>
                <br>
             <br>
              </div>
            </div>               
          </div>
        </template>
      </BaseLayout>
    </div>
  </template>
  
  <script>
  import BaseLayout from '@/layouts/BaseLayout'
  import Banner from '@/components/_base/Banner'  
  import Heading from '@/components/Heading'
  // import DatatableAdmin from '@/components/DatatableAdmin'  

  export default {
    name: 'Home',
  
    components: {
      BaseLayout,
      Heading,         
      Banner,     
    },
  
    data() {
      return {
        /** Largura da tela */
        windowWidth: window.innerWidth,     

  
        /** Categorias */
        categories: [],
  
        /** Produtos */
        products: [],
  
        /** Se os produtos foram carregados */
        loadedProducts: false,
  
        /** Se as notícias foram carregadas */
        loadedNews: false,
  
        datatable: {
        gridColumnsUpper: [
          { name: 'title', alias: 'Notícia' },
          { name: 'action', alias: 'Ação' }
        ],
        search: null,

        data: []
        }
      }
    },
    
    mounted() {
      this.$nextTick(() => {
        window.addEventListener('resize', this.onResize)
      })
    },
  
   
    computed: {
      perPageCarousel() {
        return this.windowWidth > 768 ? 3 : 1
      },
  
      /** Verificar se o botão de envio de contato deve ser habilitado */
      disabledContact() {
        return (
          this.contact.name === null ||
          this.contact.email === null ||
          this.contact.department === null ||
          this.contact.subject === null ||
          this.contact.description === ''
        )
      },
  
      /** Verificar se o botão de envio de orçamento deve ser habilitado */
      disabledBudget() {
        return (
          this.budget.name === null ||
          this.budget.email === null ||
          this.budget.representative === null ||
          this.budget.products[0].name === '' ||
          this.budget.products[0].amount === '' ||
          this.budget.subject === null ||
          this.budget.description === ''
        )
      }
    },
  
    async created() {
      await this.getNews()
    },
  
    methods: {
  
      /** Buscar as notícias */
      getNews() {
      this.$news
        .getAll()
        .then((response) => {
          const newsList = response.data.map((news) => {
            return {
              id: news.noticiaId,
              title: news.titulo,
              description: news.mensagem,
              image: news.imagem,
              video: news.video,
              status: news.ativo
            }
          })
          this.datatable.data = newsList
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar as produtos.')
        })
    },
  
      /** Buscar os departamentos */
      getDepartaments() {
        this.$departaments
          .getAll()
          .then((response) => {
            this.contact.departments = response.data.map((item) => {
              item.name = item.descricao
  
              return item
            })
          })
          .catch(function() {
            this.$toast.warning(this.$t('home.error.get-departaments'))
          })
      },    
    
    }
  }
  </script>
  
  <style lang="scss" scoped>
  .info-box {
    display: flex;
    align-items: center;
    width: 100%;
  
    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  
    &__fill {
      background: $primary;
  
      .paragraph,
      .heading {
        color: $white;
      }
    }
  
    &--desc {
      width: 100%;
      display: flex;
      justify-content: flex-end;
  
      @media screen and (max-width: 768px) {
        display: block;
      }
  
      &__right {
        justify-content: start;
      }
  
      &__gray {
        background: $background;
      }
    }
  
    &--paragraph {
      line-height: 28px;
      margin-bottom: 24px;
  
      &__spacing {
        margin-bottom: 30px;
  
        @media screen and (max-width: 768px) {
          margin-bottom: 20px;
        }
      }
  
      &__white {
        color: $white;
      }
    }
  
    &--container {
      max-width: 380px;
      margin: 100px 150px 70px 0px;
  
      @media screen and (max-width: 768px) {
        margin: 40px 20px;
      }
    }
  
    &--image {
      width: 100%;
      position: relative;
      align-self: stretch;
      overflow: hidden;
  
      @media screen and (max-width: 768px) {
        display: none;
      }
  
      img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        min-height: 100%;
        min-width: 100%;
        max-width: 150%;
      }
    }
  }
  
  .title {
    &__with-border-bottom {
      display: inline-block;
      border-bottom: 8px solid $orange;
      margin-bottom: 72px;
  
      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }
    }
    &__with-border-top {
      display: inline-block;
      border-top: 4px solid $white;
      line-height: 38px;
      margin-bottom: 0px;
    }
  }
  
  .box {
    padding: 72px 0px 100px 0px;
  
    @media screen and (max-width: 768px) {
      padding: 40px 20px;
    }
  
    &__fill {
      background: $background;
    }
  
    &--content {
      max-width: 1089px;
      margin: 0px auto;
    }
  }
  .news-bo{
    height: 400px;
    overflow: auto;
  }
  
  .slide {
    &__multiple {
      cursor: pointer;
      max-width: 311px;
      div {
        background: $gray;
        padding: 24px;
      }
    }
  
    &--image {
      max-width: 100%;
      margin: 0px auto 24px auto;
    }
  
    &--label {
      color: $secondary;
      text-align: center;
    }
  }
  
  .contact {
    &--whatsapp-icon {
      margin: 5px 0px;
      display: block;
      color: #44c054;
    }
  
    &--row {
      display: flex;
      gap: 136px;
      align-items: flex-start;
  
      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 40px;
      }
    }
  
    &--item {
      width: 100%;
    }
  
    &__info {
      position: relative;
      background: $gray;
      padding: 90px 90px;
  
      @media screen and (max-width: 768px) {
        padding: 20px;
      }
  
      div {
        margin-bottom: 64px;
  
        @media screen and (max-width: 768px) {
          margin-bottom: 30px;
        }
  
        &:last-of-type {
          margin-bottom: 0px;
        }
      }
  
      &--title {
        color: $primary;
        line-height: 23px;
        margin-bottom: 0px;
      }
  
      &--paragraph {
        line-height: 23px;
        margin-bottom: 0px;
      }
  
      &:before {
        content: '';
        position: absolute !important;
        top: 0px;
        left: 100%;
        width: 5000px;
        height: 100%;
        background: $gray;
  
        @media screen and (max-width: 768px) {
          display: none;
        }
      }
    }
  
    &__form {
      ::v-deep {
        .navbar--item {
          overflow: hidden;
        }
      }
  
      &--row {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 24px;
        margin-bottom: 32px;
  
        .form-input {
          margin-bottom: 0px;
        }
      }
  
      &--amount {
        max-width: 152px;
      }
  
      &--add {
        color: $secondary;
        font-size: 18px;
        border: 2px solid $secondary;
        border-radius: 100%;
        width: 18px;
        height: 18px;
        min-width: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 900;
        cursor: pointer;
  
        &__remove {
          transform: rotate(45deg);
        }
      }
    }
  }
  </style>
  