export default ($axios) => ({
  /** Cria uma categoria
   * @param { Object } data: "descricao" para criar uma categoria
   */
  create: (data) =>
    $axios.post('/categoria/post', {
      ...data
    }),

  /** Atualizar uma categoria
   * @param { Number } id: "categoriaId" da categoria
   * @param { Object } data: "descricao"
   */
  update: (data) =>
    $axios.put('/categoria/put', {
      ...data
    }),

  /** Busca as categorias */
  getAll: () => $axios.get('/categoria/getall'),

  /** Remove uma categoria */
  delete: (id = '') => $axios.put(`/categoria/delete?id=${id}`)
})
