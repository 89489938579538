<template>
  <div v-if="value" class="box-modal">
    <div class="box-modal--content">
      <!-- AREA - CABEÇALHO -->
      <div
        :class="{ 'box-modal--area__border': product }"
        class="box-modal--area"
      >
        <span class="box-modal--close" @click="$emit('input', false)">
          <img :src="close" alt="Fechar box-modal" />
        </span>
        <Paragraph size="40" weight="bold" class="box-modal--subtitle">{{
          subtitle
        }}</Paragraph>
        <Heading size="2" weight="bold" class="box-modal--title">{{
          title
        }}</Heading>
      </div>
      <!-- BANNER -->
      <div v-if="banner" class="box-modal--banner">
        <img :src="banner" :alt="title" />
      </div>
      <!-- DESCRIÇÃO -->
      <div v-if="description" class="box-modal--area">
        <Paragraph size="100" class="box-modal--description">
          <span v-html="description"></span>
        </Paragraph>
      </div>
      <!-- DESCRIÇÃO DE PRODUTO -->
      <div v-if="product" class="box-modal--area box-modal--area__product">
        <div class="box-modal--image">
          <img :src="image" :alt="title" />
        </div>
        <div class="box-modal--product">
          <Paragraph
            size="100"
            weight="bold"
            class="box-modal--description__title"
            >{{ titleDescription }}</Paragraph
          >
          <Paragraph size="100" class="box-modal--description">{{
            descriptionProduct
          }}</Paragraph>
        </div>
      </div>
    </div>
    <!-- OVERLAY -->
    <div class="box-modal--overlay" @click="$emit('input', false)"></div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import Heading from '@/components/Heading'

import close from '@/assets/images/close.svg'

/** Componente padrão de modais */
export default {
  name: 'Modal',
  components: {
    Paragraph,
    Heading
  },
  props: {
    /** Título da box-modal */
    title: {
      type: String,
      default: 'Título'
    },
    /** Subtítulo da box-modal */
    subtitle: {
      type: String,
      default: 'Subtítulo'
    },
    /** Banner */
    banner: {
      type: [String, Boolean],
      default: ''
    },
    /** Imagem (não fullscreen) */
    image: {
      type: String,
      default: ''
    },
    /** Título da descrição */
    titleDescription: {
      type: String,
      default: ''
    },
    /** Descrição */
    description: {
      type: String,
      default: ''
    },
    /** Descrição do produto */
    descriptionProduct: {
      type: String,
      default: ''
    },
    /** Navegação */
    navigation: {
      type: Boolean,
      default: false
    },
    /** Anterior */
    prev: {
      type: String,
      default: ''
    },
    /** Próximo */
    next: {
      type: String,
      default: ''
    },
    /** Se é uma modal de produtos */
    product: {
      type: Boolean,
      default: false
    },
    /** Visibilidade da box-modal */
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      close: close /** Ícone de fechar */
    }
  }
}
</script>

<style lang="scss" scoped>
.box-modal {
  position: fixed;
  top: 0%;
  left: 0%;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &--content {
    width: 100%;
    max-width: 870px;
    background: $white;
    margin: 20px;
    border-radius: 4px;
    position: relative;
    max-height: 90%;
    overflow-y: auto;
  }

  &--overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    cursor: pointer;
    position: absolute;
    top: 0%;
    left: 0%;
    z-index: -1;
  }

  &--area {
    padding: 40px 115px;

    @media screen and (max-width: 768px) {
      padding: 20px 20px;
    }

    &__border {
      border-bottom: 1px solid $tertiary;
    }

    &__product {
      display: flex;
      align-items: flex-start;
      gap: 40px;

      @media screen and (max-width: 768px) {
        flex-direction: column;
        gap: 20px;
      }
    }
  }

  &--banner {
    height: 290px;
    overflow: hidden;
    position: relative;

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 100%;
      min-width: 100%;
      max-width: 150%;
    }
  }

  &--close {
    width: 40px;
    height: 40px;
    border-radius: 100%;
    border: 1px solid $tertiary;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    top: 0px;
    right: 0px;
    margin: 25px;

    @media screen and (max-width: 768px) {
      margin: 20px;
    }

    img {
      width: 8px;
    }
  }

  &--subtitle {
    text-transform: uppercase;
    color: #e56000;
    border-top: 2px solid #e56000;
    display: inline-block;
    padding-top: 10px;
  }

  &--title {
    line-height: 42px;
    color: $fontColor;
    margin-bottom: 0px;
  }

  &--description {
    line-height: 28px;

    &__title {
      color: $primary;
    }

    &::v-deep {
      p {
        margin-bottom: 10px;
        display: block;

        &:last-of-type {
          margin-bottom: 0px;
        }
      }

      ul {
        margin-bottom: 10px;
        padding-left: 20px;
        list-style: disc;
      }
    }
  }

  &--image {
    width: 100%;
    background: $gray;
    border-radius: 4px;
    padding: 32px;
    max-width: 311px;

    @media screen and (max-width: 768px) {
      margin: 0px auto;
    }

    img {
      width: 100%;
      border-radius: 4px;
    }
  }

  &--product {
    width: 100%;
  }
}
</style>
