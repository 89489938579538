<template>
  <div class="form-input">
    <div class="form-input--body">
      <!-- LABEL -->
      <label
        v-if="label"
        :for="`input-${id}`"
        :class="{
          'form-input--label__filled': !isEmpty() || focus,
          'form-input--label__filled__admin': (!isEmpty() || focus) && admin
        }"
        class="form-input--label"
      >
        <Paragraph :size="focus || !isEmpty() ? 40 : 80" weight="semi-bold">
          {{ label }}
        </Paragraph>
      </label>

      <!-- ENTRADA DE TEXTO -->
      <input
        v-mask="mask"
        :value="value"
        :type="type"
        :id="`input-${id}`"
        :class="{ 'form-input--field__admin': admin }"
        class="form-input--field"
        autocomplete="off"
        @input="$emit('input', $event.target.value)"
        @focus="
          $emit('focus', $event)
          focus = true
        "
        @blur="
          $emit('blur', $event)
          focus = false
        "
      />
    </div>

    <!-- ALERTA -->
    <Paragraph size="40" weight="semi-bold" class="form-input--alert">
      {{ alert }}
    </Paragraph>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de input */
export default {
  name: 'FormInput',

  components: {
    Paragraph
  },

  props: {
    type: {
      type: String,
      default: 'text'
    },

    /** Label do campo */
    label: {
      type: String,
      default: 'Insira o conteúdo'
    },

    /** Alerta/Aviso */
    alert: {
      type: String,
      default: ''
    },

    /** Máscara do input */
    mask: {
      type: String,
      default: ''
    },

    /** Nome do campo */
    name: {
      type: String,
      default: ''
    },

    /** Valor do campo */
    value: {
      type: [String, Number, Object, Array],
      default: ''
    },

    /** Se é admin */
    admin: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      id: this._uid /** Id único do componente */,
      focus: false /** Foco no componente */
    }
  },

  methods: {
    isEmpty() {
      return this.value === '' || this.value === null
    }
  }
}
</script>

<style lang="scss" scoped>
.form-input {
  width: 100%;
  margin-bottom: 30px;

  &--body {
    position: relative;
    width: 100%;
    overflow: hidden;
    background: $white;
    border-radius: 5px;
    margin-bottom: 2px;
  }

  &--label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    padding: 0px 16px;
    transition: 0.2s all;

    &__filled {
      top: 11px;
      transform: translateY(0%);

      &__admin {
        top: 9px;
      }
    }
  }

  &--field {
    background: $white;
    color: $fontColor;
    width: 100%;
    border: 0;
    padding: 30px 16px 10px 16px;
    outline: none;

    &__admin {
      border-radius: 5px;
      padding: 19px 16px 10px 16px;
      border: 1px solid $gray;
    }
  }

  &--alert {
    color: $fontColorSecondary;
    margin: 0px 16px;
    line-height: 18px;
  }
}
</style>
