<template>
  <component
    v-bind="{
      to: element === 'router-link' ? link : false
    }"
    :is="element"
    :href="element === 'a' ? link : false"
    :target="target"
    :class="[
      `button__${color}`,
      { button__active: active, button__disabled: disabled }
    ]"
    class="button"
    @click="$emit('click', $event)"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <slot> Botão </slot>
  </component>
</template>

<script>
/** Botão padrão */

export default {
  name: 'Button',
  props: {
    /**
     * Elemento html do botão
     * @values 'button', 'a' ou 'router-link'
     */
    element: {
      type: String,
      default: 'button'
    },
    /** Link para quando element for router-link */
    link: {
      type: String,
      default: '/'
    },
    /** Possibilita o tipo de "target"
     * @values '_self', '_blank'
     */
    target: {
      type: String,
      default: '_self'
    },
    /** Cor do botão
     * @values 'primary', 'secondary'
     */
    color: {
      type: String,
      default: 'primary'
    },
    /** Desabilita o botão */
    disabled: {
      type: Boolean,
      default: false
    },
    /** Se o botão está ativado */
    active: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.button {
  border: 0px;
  border-radius: 3px;
  padding: 16px 24px;
  font-size: paragraph-size('paragraph-80');
  font-weight: font-weight('weight-bold');
  color: $white;
  border: 1px solid $primary;
  transition: 0.2s all;
  text-align: center;

  /** Cores */
  &__primary {
    background-color: $primary;
    border-color: $primary;

    &:hover {
      background-color: $primaryDark;
      border-color: $primaryDark;
    }
  }

  &__secondary {
    background-color: $secondary;
    border-color: $secondary;

    &:hover {
      background-color: $white;
      color: $secondary;
    }
  }

  &__danger {
    background-color: $danger;
    border-color: $danger;
    color: $white;

    &:hover {
      background-color: $white;
      color: $danger;
    }
  }

  &__outline {
    background-color: $white;
    border-color: $primary;
    color: $primary;

    &:hover {
      background-color: $primary;
      color: $white;
    }
  }

  /** Ativo */
  &__active {
    background-color: $white;
    color: $primary;
  }

  /** Desabilitar botão */
  &__disabled {
    opacity: 0.3;
    pointer-events: none;
  }
}
</style>
