var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-file"},[_c('label',{staticClass:"form-file--label",class:{
      'form-file--label__placeholder': !_vm.value,
      'form-file--label__admin': _vm.admin
    },attrs:{"for":`form-file-${_vm.id}`}},[_c('Paragraph',{attrs:{"size":"60","weight":"weight-normal"}},[_vm._v(" "+_vm._s(_vm.fileName)+" ")]),_c('font-awesome-icon',{attrs:{"icon":"fa-solid fa-file","size":"lg"}})],1),_c('input',{staticClass:"form-file--field",class:{
      'form-file--field__radius': _vm.radius,
      'form-file--field__border': _vm.border
    },attrs:{"id":`form-file-${_vm.id}`,"type":"file"},on:{"change":_vm.onChange}})])
}
var staticRenderFns = []

export { render, staticRenderFns }