<template>
  <div class="home">
    <AdminLayout page="Categorias">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="5" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE CATEGORIAS -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="name"
            :admin="true"
            label="Nome"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createCategory()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <DatatableAdmin
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.data.length"
          :page="datatable.page"
          title="Categorias cadastrados"
          termResults="resultados encontrados"
          class="datatable"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR CATEGORIA -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER CATEGORIAS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-trash-can"
                @click="dialogCorpus(slotProps.row.id)"
              />
            </div>
          </template>
        </DatatableAdmin>

        <!-- EDITAR CATEGORIAS -->
        <ModalAdmin
          v-model="category.edit"
          title="EDITAR CATEGORIA"
          class="modal-edit__edit"
        >
          <!-- NOME DO CATEGORIAS -->
          <FormInput
            v-model="category.name"
            :admin="true"
            :border="true"
            label="Nome do Corpus"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveCategory()"
            >
              GRAVAR
            </Button>
          </div>
        </ModalAdmin>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE CATEGORIAS -->
        <ModalAdmin
          v-model="category.delete"
          title="REMOVER CATEGORIAS"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover a categoria:
            <strong>{{ category.name }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalCategory()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteCategory()"
            >
              DELETAR
            </Button>
          </div>
        </ModalAdmin>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import DatatableAdmin from '@/components/DatatableAdmin'
import ModalAdmin from '@/components/ModalAdmin'

export default {
  name: 'Categories',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    DatatableAdmin,
    ModalAdmin
  },

  data() {
    return {
      /** Nome da categoria */
      name: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'name', alias: 'Categorias' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: []
      },

      /** Visibilidade da modal */
      modalVisibility: false,

      /** Dados da modal */
      modal: {
        id: null,
        title: null,
        products: []
      },

      /** Categoria a ser editado */
      category: {
        id: null /** ID da categoria */,
        name: null /** Nome da categoria */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Buscar as categorias */
    this.getCategories()
  },

  methods: {
    /** Buscar as categorias */
    getCategories() {
      this.$categories
        .getAll()
        .then((response) => {
          const categories = response.data.map((category) => {
            return {
              id: category.categoriaId,
              name: category.descricao
            }
          })
          this.datatable.data = categories
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar as categorias.')
        })
    },

    /** Criar categoria */
    createCategory() {
      this.$categories
        .create({ descricao: this.name })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Categoria criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar a categoria!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.name = null
          /** Recarregar a lista */
          this.getCategories()
        })
    },

    /** Exibir os arquivos da categoria
     * @param {Number} id: Id da categoria
     */
    showWordlists(id) {
      const category = this.datatable.data.find((item) => item.id === id)
      if (category) {
        this.modal.id = category.id
        this.modal.title = category.name
        this.modal.files = category.wordlistsData
        this.modalVisibility = true
      } else {
        this.$toast.warning('Houve um erro ao encontrar a categoria.')
        this.closeModalCategory()
      }
    },

    /** Exibir a modal de edição da categoria
     * @param {Number} id: Id da categoria
     */
    edit(id) {
      /** Localizar a categoria na listagem */
      const category = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados da categoria e abrir a modal */
      if (category) {
        this.category.edit = true
        this.category.id = category.id
        this.category.name = category.name
      } else {
        this.$toast.warning('Houve um erro ao encontrar a categoria.')
        this.closeModalCategory()
      }
    },

    /** Confirmar se deseja remover a categoria
     * @param {Number} id: Id da categoria
     */
    dialogCorpus(id) {
      /** Localizar a categoria na listagem */
      const category = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados da categoria e abrir a modal de confirmação */
      if (category) {
        this.category.delete = true
        this.category.id = category.id
        this.category.name = category.name
      } else {
        this.$toast.warning('Houve um erro ao encontrar a categoria.')
        this.closeModalCategory()
      }
    },

    /** Fechar a modal de edição de categoria */
    closeModalCategory() {
      this.modal.id = null
      this.modal.title = null
      this.modal.files = []
      this.category.edit = false
      this.category.delete = false
      this.category.id = null
      this.category.name = null
      this.modalVisibility = false
    },

    /** Buscar as categorias */
    saveCategory() {
      this.$categories
        .update({
          categoriaId: this.category.id,
          descricao: this.category.name,
          ativo: true
        })
        .then(() => {
          this.$toast.success('Categoria atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar a categoria.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCategory()
          /** Recarregar a listagem */
          this.getCategories()
        })
    },

    /** Remover a categoria */
    deleteCategory() {
      this.$categories
        .delete(this.category.id)
        .then(() => {
          this.$toast.success('Categoria removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover a categoria.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalCategory()

          setTimeout(() => {
            /** Recarregar a listagem */
            this.getCategories()
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(2),
    tr td:nth-child(2) {
      width: 120px;
      text-align: center;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }
}

/** MODAL DE EDIÇÃO DO CATEGORIAS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
