export default ($axios) => ({
  /** Cria um produto
   * @param { String } destinatario: Título do produto
   * @param { String } assunto: Subtítulo do produto
   * @param { String } mensagem: Descrição do produto
   * @param { String } destinatario: Destinatário do e-mail
   */
  contact: (data) =>
    $axios.post('/email/sendmail', {
      destinatario: 'theonas@tutanota.com',
      ...data
    })
})
