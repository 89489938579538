<template>
  <div class="home">
    <AdminLayout page="Representantes">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="5" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE REPRESENTANTES -->
        <div class="form">
          <!-- CAMPO DO NOME -->
          <FormInput
            v-model="name"
            :admin="true"
            label="Nome"
            class="form--input"
          />

          <!-- CAMPO DO EMAIL -->
          <FormInput
            v-model="email"
            :admin="true"
            type="email"
            label="E-mail"
            class="form--input"
          />

          <!-- CAMPO DO UF -->
          <FormInput
            v-model="uf"
            :admin="true"
            label="UF"
            class="form--input"
          />

          <!-- CAMPO DO TELEFONE -->
          <FormInput
            v-model="phone"
            :admin="true"
            :mask="
              phone && phone.length > 14 ? '(##) #####-####' : '(##) ####-####'
            "
            label="Telefone"
            class="form--input"
          />

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button"
            @click="createDepartament()"
          >
            CADASTRAR
          </Button>
        </div>

        <!-- TABELA -->
        <DatatableAdmin
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.data.length"
          :page="datatable.page"
          title="Representantes cadastrados"
          termResults="resultados encontrados"
          class="datatable"
        >
          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR Representante -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER REPRESENTANTES -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-trash-can"
                @click="dialogCorpus(slotProps.row.id)"
              />
            </div>
          </template>
        </DatatableAdmin>

        <!-- EDITAR REPRESENTANTES -->
        <ModalAdmin
          v-model="representative.edit"
          title="EDITAR Representante"
          class="modal-edit__edit"
        >
          <!-- NOME DO Representante -->
          <FormInput
            v-model="representative.name"
            :admin="true"
            :border="true"
            label="Nome do Representante"
            class="modal-edit--input"
          />

          <!-- E-MAIL DO Representante -->
          <FormInput
            v-model="representative.email"
            :admin="true"
            :border="true"
            label="E-mail do Representante"
            class="modal-edit--input"
          />

          <!-- CAMPO DO UF -->
          <FormInput
            v-model="representative.uf"
            :admin="true"
            label="UF"
            class="modal-edit--input"
          />

          <!-- CAMPO DO TELEFONE -->
          <FormInput
            v-model="representative.phone"
            :admin="true"
            :mask="
              representative.phone && representative.phone.length > 14
                ? '(##) #####-####'
                : '(##) ####-####'
            "
            label="Telefone"
            class="modal-edit--input"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveDepartament()"
            >
              GRAVAR
            </Button>
          </div>
        </ModalAdmin>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE REPRESENTANTES -->
        <ModalAdmin
          v-model="representative.delete"
          title="REMOVER REPRESENTANTES"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o Representante:
            <strong>{{ representative.name }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalDepartament()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteDepartament()"
            >
              DELETAR
            </Button>
          </div>
        </ModalAdmin>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import FormInput from '@/components/FormInput'
import Button from '@/components/Button'
import DatatableAdmin from '@/components/DatatableAdmin'
import ModalAdmin from '@/components/ModalAdmin'

export default {
  name: 'Representatives',

  components: {
    AdminLayout,
    Heading,
    Paragraph,
    FormInput,
    Button,
    DatatableAdmin,
    ModalAdmin
  },

  data() {
    return {
      /** Nome do Representante */
      name: null,

      /** E-mail do Representante */
      email: null,

      /** UF do Representante */
      uf: null,

      /** Telefone do Representante */
      phone: null,

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'name', alias: 'Representante' },
          { name: 'email', alias: 'E-mail' },
          { name: 'uf', alias: 'UF' },
          { name: 'phone', alias: 'Telefone' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: []
      },

      /** Visibilidade da modal */
      modalVisibility: false,

      /** Dados da modal */
      modal: {
        id: null,
        title: null,
        email: null,
        uf: null,
        phone: null
      },

      /** Representante a ser editado */
      representative: {
        id: null /** ID do Representante */,
        name: null /** Nome do Representante */,
        email: null /** E-mail do Representante */,
        uf: null /** UF do Representante */,
        phone: null /** Telefone do Representante */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  created() {
    /** Buscar os Representantes */
    this.getRepresentatives()
  },

  methods: {
    /** Buscar os Representantes */
    getRepresentatives() {
      this.$representatives
        .getAll()
        .then((response) => {
          const representatives = response.data.map((representative) => {
            return {
              id: representative.representanteId,
              name: representative.nome,
              email: representative.email,
              uf: representative.uf,
              phone: representative.telefone
            }
          })
          this.datatable.data = representatives
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar os Representantes.')
        })
    },

    /** Criar Representante */
    createDepartament() {
      this.$representatives
        .create({
          nome: this.name,
          email: this.email,
          uf: this.uf,
          telefone: this.phone
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Representante criado com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o representante!')
        })
        .finally(() => {
          /** Limpar os campo */
          this.name = null
          this.email = null
          this.uf = null
          this.phone = null
          /** Recarregar a lista */
          this.getRepresentatives()
        })
    },

    /** Exibir a modal de edição do Representante
     * @param {Number} id: Id do Representante
     */
    edit(id) {      
      /** Localizar o representante na listagem */
      const representative = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do Representante e abrir a modal */
      if (representative) {
        this.representative.edit = true
        this.representative.id = representative.id
        this.representative.name = representative.name
        this.representative.email = representative.email
        this.representative.uf = representative.uf
        this.representative.phone = representative.phone
      } else {
        this.$toast.warning('Houve um erro ao encontrar o representante.')
        this.closeModalDepartament()
      }
    },

    /** Confirmar se deseja remover o Representante
     * @param {Number} id: Id do Representante
     */
    dialogCorpus(id) {
      /** Localizar o representante na listagem */
      const representative = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do Representante e abrir a modal de confirmação */
      if (representative) {
        this.representative.delete = true
        this.representative.id = representative.id
        this.representative.name = representative.name
        this.representative.email = representative.email
        this.representative.uf = representative.uf
        this.representative.phone = representative.phone
      } else {
        this.$toast.warning('Houve um erro ao encontrar o representante.')
        this.closeModalDepartament()
      }
    },

    /** Fechar a modal de edição de representante */
    closeModalDepartament() {
      this.modal.id = null
      this.modal.title = null
      this.modal.email = null
      this.modal.files = []
      this.representative.edit = false
      this.representative.delete = false
      this.representative.id = null
      this.representative.name = null
      this.representative.email = null
      this.representative.uf = null
      this.representative.phone = null
      this.modalVisibility = false
    },

    /** Buscar os Representantes */
    saveDepartament() {      
      this.$representatives
        .update({
          representanteId: this.representative.id,
          nome: this.representative.name,
          email: this.representative.email,
          uf: this.representative.uf,
          telefone: this.representative.phone,
          ativo: true
        })
        .then(() => {
          this.$toast.success('Representante atualizada com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar o representante.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalDepartament()
          /** Recarregar a listagem */
          this.getRepresentatives()
        })
    },

    /** Remover o Representante */
    deleteDepartament() {
      this.$representatives
        .delete(this.representative.id)
        .then(() => {
          this.$toast.success('Representante removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o Representante.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalDepartament()

          setTimeout(() => {
            /** Recarregar a listagem */
            this.getRepresentatives()
          }, 1000)
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  display: flex;
  align-items: center;
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--input {
    margin: 0px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(5),
    tr td:nth-child(5) {
      width: 120px;
      text-align: center;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }
}

/** MODAL DE EDIÇÃO DO REPRESENTANTES */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 600px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
