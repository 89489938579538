<template>
  <div class="menu-admin">
    <div class="menu-admin--header">
      <router-link to="/">
        <img :src="logo" class="header--logo--image" />
      </router-link>
    </div>
    <div class="menu-admin--links">
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Início' }"
        to="/admin"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">INÍCIO</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Categorias' }"
        to="/admin/categorias"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">CATEGORIAS</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Noticias' }"
        to="/admin/noticias"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">NOTÍCIAS</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Produtos' }"
        to="/admin/produtos"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">PRODUTOS</Paragraph>
      </router-link>
      <router-link
        :class="{
          'menu-admin--links__item__active': active === 'Departamentos'
        }"
        to="/admin/departamentos"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">DEPARTAMENTOS</Paragraph>
      </router-link>
      <router-link
        :class="{
          'menu-admin--links__item__active': active === 'Representantes'
        }"
        to="/admin/representantes"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">REPRESENTANTES</Paragraph>
      </router-link>
      <router-link
        :class="{ 'menu-admin--links__item__active': active === 'Usuários' }"
        to="/admin/usuarios"
        class="menu-admin--links__item"
      >
        <Paragraph size="40" weight="medium">USUÁRIOS</Paragraph>
      </router-link>
    </div>
    <div class="menu-admin--footer">
      <Paragraph class="footer--rights" size="20" weight="medium">
        ©2023 Todos os direitos reservados
      </Paragraph>
    </div>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import logo from '@/assets/images/logo.png'

// import { mapGetters } from 'vuex'

/** Componente padrão do menu lateral do admin */
export default {
  name: 'MenuAdmin',
  components: {
    Paragraph
  },
  // computed: {
  //   ...mapGetters('user', ['getRole'])
  // },
  data() {
    return {
      /** Imagem do logo */
      logo: logo
    }
  },
  props: {
    /** Link ativo */
    active: {
      type: String,
      default: ''
    }
  }
}
</script>

<style lang="scss" scoped>
.menu-admin {
  background: $primary;
  height: 100vh;
  display: flex;
  flex-direction: column;
  border-right: 1px solid $gray;

  position: sticky;
  top: 0px;

  .paragraph {
    color: $white;
  }

  &--header {
    background: $white;
    padding: 25px 0px;

    img {
      margin: 0px auto;
      max-width: 100%;
      display: block;
    }
  }

  &--links {
    display: flex;
    flex-direction: column;
    flex: 1;

    &__item {
      padding: 20px;
      border-bottom: 1px solid $primaryOpacity;
      transition: 0.2s all;

      &:hover,
      &__active {
        background: $active;
        .paragraph {
          margin: 0px;
        }
      }
    }
  }

  &--footer {
    padding: 20px 0px;
    text-align: center;
  }
}
</style>
