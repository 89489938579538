<template>
  <div class="home">
    <BaseLayout>
      <template #content>
        <!-- BANNERS -->
        <Banner :banners="banners"></Banner>

        <!-- INFORMAÇÃO -->
        <div class="info-box" id="who-we-are">
          <div class="info-box--desc info-box--desc__gray">
            <div class="info-box--container">
              <Heading
                size="1"
                weight="x-bold"
                class="title__with-border-bottom"
                >{{ $t('home.title') }}</Heading
              >

              <Paragraph size="100" class="info-box--paragraph">{{
                $t('home.description1')
              }}</Paragraph>

              <Button @click="aboutUs = !aboutUs">{{
                $t('home.knowMore')
              }}</Button>
            </div>
          </div>

          <div class="info-box--image">
            <img :src="image" :alt="$t('home.title')" />
          </div>
        </div>

        <!-- MODAL -->
        <Modal
          v-model="aboutUs"
          :banner="image"
          :title="$t('home.title')"
          :subtitle="$t('home.whoWeAre')"
          :description="aboutUsDescription[$i18n.locale]"
        ></Modal>

        <!-- INFORMAÇÃO -->
        <div class="info-box info-box__fill">
          <div class="info-box--desc">
            <div class="info-box--container">
              <Paragraph
                size="140"
                class="info-box--paragraph info-box--paragraph__spacing"
              >
                {{ $t('home.description2') }}
              </Paragraph>

              <Paragraph size="100" class="info-box--paragraph">
                {{ $t('home.description3') }}
              </Paragraph>
            </div>
          </div>

          <div class="info-box--desc info-box--desc__right">
            <div class="info-box--container">
              <Heading
                weight="bolder"
                size="4"
                class="title__with-border-top"
                >{{ $t('home.labelTitle1') }}</Heading
              >

              <Paragraph size="100" class="info-box--paragraph">{{
                $t('home.labelDescription1')
              }}</Paragraph>

              <Heading
                weight="bolder"
                size="4"
                class="title__with-border-top"
                >{{ $t('home.labelTitle2') }}</Heading
              >

              <Paragraph size="100" class="info-box--paragraph">{{
                $t('home.labelDescription2')
              }}</Paragraph>

              <Heading
                weight="bolder"
                size="4"
                class="title__with-border-top"
                >{{ $t('home.labelTitle3') }}</Heading
              >

              <Paragraph size="100" class="info-box--paragraph">{{
                $t('home.labelDescription3')
              }}</Paragraph>
            </div>
          </div>
        </div>

        <!-- PRINCÍPIOS, MAQUINÁRIOS E CERTIFICAÇÃO -->
        <div class="box box__fill">
          <div class="box--content">
            <Navbar v-model="navbarSelected" :options="options">
              <template v-slot:principles>
                <Label v-for="(principle, i) in principles" :key="i">{{
                  principle[$i18n.locale]
                }}</Label>
              </template>

              <template v-slot:machinery>
                <Datatable
                  :cols="machinery.gridColumns"
                  :rows="machinery.data[$i18n.locale]"
                ></Datatable>
              </template>
            </Navbar>
          </div>
        </div>

        <!-- PRODUTOS -->
        <div class="box " id="products">
          <div class="box--content">
            <Heading size="1" weight="x-bold">{{
              $t('home.menu.products')
            }}</Heading>

            <Paragraph size="140">{{
              $t('home.products.description')
            }}</Paragraph>

            <!-- CATEGORIAS E PRODUTOS -->
            <div v-if="loadedProducts">
              <div v-for="(category, i) in categories" :key="i">
                
                <MyCarousel
                  v-if="category.products && category.products.length"
                  :autoplay="false"
                  :perPage="perPageCarousel"
                  :multiple="true"
                  :name="category.descricao"
                >
                  <!-- SLIDES -->
                  <Slide
                    v-for="(product, i) in category.products"
                    :key="i"
                    class="slide__multiple"
                  >
                    <div @click="openModalProductions(product)">
                      <img :src="product.imagem" class="slide--image" />
                      <Heading size="80" weight="bold" class="slide--label">
                        {{ product.titulo }}
                      </Heading>
                    </div>
                  </Slide>
                </MyCarousel>
              </div>
            </div>

            <!-- MODAL DO CAROUSEL -->
            <Modal
              v-model="productions.visible"
              :image="productions.image"
              :title="productions.title"
              :subtitle="productions.subtitle"
              :titleDescription="productions.titleDescription"
              :descriptionProduct="productions.descriptionProduct"
              :product="true"
            ></Modal>
          </div>
        </div>

        <!-- CONTATO -->
        <div class="box box__fill" id="contact">
          <div class="box--content">
            <Heading size="1" weight="x-bold">{{
              $t('home.menu.contact')
            }}</Heading>
            <div class="contact">
              <div class="contact--row">
                <div class="contact--item contact__form">
                  <Navbar v-model="contactSelected" :options="optionsContact">
                    <template v-slot:contact>
                      <!-- NOME -->
                      <FormInput
                        v-model="contact.name"
                        :label="`${$t('home.contact.name')} *`"
                      ></FormInput>
                      <!-- E-MAIL -->
                      <FormInput
                        v-model="contact.email"
                        :label="`${$t('home.contact.email')} *`"
                        :alert="$t('home.contact.alertEmail')"
                        type="email"
                      ></FormInput>
                      <!-- DEPARTAMENTOS -->
                      <FormSelect
                        v-model="contact.department"
                        :options="contact.departments"
                        :label="$t('home.contact.department')"
                        :isObject="true"
                      ></FormSelect>
                      <!-- ASSUNTO -->
                      <FormInput
                        v-model="contact.subject"
                        :label="`${$t('home.contact.subject')} *`"
                      ></FormInput>
                      <!-- DESCRIÇÃO -->
                      <Textarea
                        v-model="contact.description"
                        :label="`${$t('home.contact.comments')} *`"
                      ></Textarea>
                      <!-- ENVIAR -->
                      <Button
                        :disabled="disabledContact"
                        @click="sendEmailContact()"
                        >{{ $t('home.contact.send') }}</Button
                      >
                    </template>
                    <template v-slot:budget>
                      <!-- NOME -->
                      <FormInput
                        v-model="budget.name"
                        :label="`${$t('home.contact.name')} *`"
                      />
                      <!-- E-MAIL -->
                      <FormInput
                        v-model="budget.email"
                        :label="`${$t('home.contact.email')} *`"
                        :alert="$t('home.contact.alertEmail')"
                        type="email"
                      />
                      <!-- REPRESENTANTE -->
                      <FormSelect
                        v-model="budget.representative"
                        :options="budget.representatives"
                        :label="$t('home.contact.representative')"
                        :isObject="true"
                      ></FormSelect>
                      <!-- PRODUTOS -->
                      <div
                        v-for="(product, i) in budget.products"
                        :key="i"
                        class="contact__form--row"
                      >
                        <!-- NOME DO PRODUTO -->
                        <FormInput
                          v-model="product.name"
                          :label="`${$t('home.contact.product')} *`"
                        />
                        <!-- QUANTIDADE -->
                        <FormInput
                          v-model="product.amount"
                          :label="`${$t('home.contact.amount')} *`"
                          type="number"
                          class="contact__form--amount"
                        />
                        <!-- ADICIONAR -->
                        <span
                          v-if="budget.products.length === i + 1"
                          class="contact__form--add"
                          @click="addProducts()"
                          >+</span
                        >
                        <!-- REMOVER -->
                        <span
                          v-else
                          class="contact__form--add contact__form--add__remove"
                          @click="removeProducts(i)"
                          >+</span
                        >
                      </div>
                      <!-- ASSUNTO -->
                      <FormInput v-model="budget.subject" label="Assunto *" />
                      <!-- DESCRIÇÃO -->
                      <Textarea
                        v-model="budget.description"
                        :label="`${$t('home.contact.comments')} *`"
                      ></Textarea>
                      <!-- ENVIAR -->
                      <Button
                        :disabled="disabledBudget"
                        @click="sendEmailBudget()"
                        >{{ $t('home.contact.send') }}</Button
                      >
                    </template>
                  </Navbar>
                </div>
                <div class="contact--item contact__info">
                  <div>
                    <Heading
                      size="5"
                      weight="bold"
                      class="contact__info--title"
                      >{{ $t('home.details.commercial') }}</Heading
                    >
                    <Paragraph
                      size="100"
                      weight="semi-bold"
                      class="contact__info--paragraph"
                      >(51) 99879-2408</Paragraph
                    >
                    <Paragraph
                      size="100"
                      weight="semi-bold"
                      class="contact__info--paragraph"
                      >comercial@rsforjaria.com.br</Paragraph
                    >
                    <a
                      class="contact--whatsapp-icon"
                      href="https://api.whatsapp.com/send?phone=5551998792408&text=RS%20FORJARIA"
                      target="_blank"
                    >
                      <font-awesome-icon icon="fa-brands fa-whatsapp" />
                    </a>
                  </div>
                  <div>
                    <Heading
                      size="5"
                      weight="bold"
                      class="contact__info--title"
                      >{{ $t('home.details.financial') }}</Heading
                    >
                    <Paragraph
                      size="100"
                      weight="semi-bold"
                      class="contact__info--paragraph"
                      >(51) 99793-1809</Paragraph
                    >
                    <Paragraph
                      size="100"
                      weight="semi-bold"
                      class="contact__info--paragraph"
                      >financeiro@rsforjaria.com.br</Paragraph
                    >
                    <a
                      class="contact--whatsapp-icon"
                      href="https://api.whatsapp.com/send?phone=5551997931809&text=RS%20FORJARIA"
                      target="_blank"
                    >
                      <font-awesome-icon icon="fa-brands fa-whatsapp" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- MODAL -->
        <Modal
          v-model="successContact"
          :banner="false"
          :title="$t('home.contact.feedbackTitle')"
          :subtitle="$t('home.contact.contactUs')"
          :description="$t('home.contact.feedbackMessage')"
        ></Modal>

        <!-- MODAL -->
        <Modal
          v-model="successBudget"
          :banner="false"
          :title="$t('home.contact.feedbackTitle')"
          :subtitle="$t('home.contact.budget')"
          :description="$t('home.contact.feedbackMessage')"
        ></Modal>



 
      </template>
    </BaseLayout>
  </div>
</template>

<script>
import BaseLayout from '@/layouts/BaseLayout'
import Banner from '@/components/_base/Banner'

import MyCarousel from '@/components/MyCarousel'
import Heading from '@/components/Heading'
import Paragraph from '@/components/Paragraph'
import Button from '@/components/Button'
import Navbar from '@/components/Navbar'
import Label from '@/components/Label'
import Datatable from '@/components/Datatable'
import Modal from '@/components/Modal'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import Textarea from '@/components/Textarea'

import { Slide } from 'vue-carousel'

import image1 from '@/assets/images/image-1.jpg'
import image2 from '@/assets/images/image-2.jpg'
import image3 from '@/assets/images/image-3.jpg'
import image4 from '@/assets/images/image-4.jpg'

import escavaparts from '@/assets/images/partners/escava_parts.jpg'
 import micromazza from '@/assets/images/partners/micromazza.jpg'
 import rda from '@/assets/images/partners/rda.png'
 import termac from '@/assets/images/partners/termac.jpg'

import imgCertificate from '@/assets/images/imgCertificate.png'

export default {
  name: 'Home',

  components: {
    BaseLayout,
    MyCarousel,
    Heading,
    Paragraph,
    Button,
    Navbar,
    Label,
    Slide,
    Banner,
    Datatable,
    Modal,
    FormInput,
    FormSelect,
    Textarea
  },

  data() {
    return {
      escavaparts: escavaparts,
      micromazza: micromazza,
      rda:rda,
      termac:termac,
      /** Largura da tela */
      windowWidth: window.innerWidth,

      /** Informações dos banners */
      banners: [
        {
          title: {
            'pt-BR': 'Mais de 21 anos forjando soluções de qualidade',
            'en-US': 'More than 21 years forging quality solutions'
          },
          buttonText: {
            'pt-BR': 'Conheça a forjaria',
            'en-US': 'Discover the forge'
          },
          link: '',
          target: '_blank',
          image: image1
        },
        {
          title: {
            'pt-BR': 'Mais de 21 anos forjando soluções de qualidade',
            'en-US': 'More than 21 years forging quality solutions'
          },
          buttonText: {
            'pt-BR': 'Conheça a forjaria',
            'en-US': 'Discover the forge'
          },
          link: '',
          target: '_blank',
          image: image2
        },
        {
          title: {
            'pt-BR': 'Mais de 21 anos forjando soluções de qualidade',
            'en-US': 'More than 21 years forging quality solutions'
          },
          buttonText: {
            'pt-BR': 'Conheça a forjaria',
            'en-US': 'Discover the forge'
          },
          link: '',
          target: '_blank',
          image: image3
        },
        {
          title: {
            'pt-BR': 'Mais de 21 anos forjando soluções de qualidade',
            'en-US': 'More than 21 years forging quality solutions'
          },
          buttonText: {
            'pt-BR': 'Conheça a forjaria',
            'en-US': 'Discover the forge'
          },
          link: '',
          target: '_blank',
          image: image4
        }
      ],

      /** Princípios */
      principles: [
        {
          'pt-BR': 'Responsabilidade social com a comunidade',
          'en-US': 'Social responsibility with the community'
        },
        {
          'pt-BR': 'Sustentabilidade do meio ambiente',
          'en-US': 'Environmental sustainabilitye'
        },
        {
          'pt-BR': 'Transparência nas decisões',
          'en-US': 'Transparency in decisions'
        },
        {
          'pt-BR': 'Espírito de equipe',
          'en-US': 'Team spirit'
        },
        {
          'pt-BR': 'Desenvolvimento pessoal e profissional',
          'en-US': 'Personal and professional development'
        },
        {
          'pt-BR': 'Comprometimento',
          'en-US': 'Commitment'
        },
        {
          'pt-BR': 'Pontualidade nas entregas',
          'en-US': 'Punctuality in deliveries'
        },
        {
          'pt-BR': 'Qualidade de produção',
          'en-US': 'Production quality'
        }
      ],

      image: image2,

      /** Navbar principal */
      navbarSelected: 'principles',
      options: [
        {
          name: 'principles',
          title: this.$t('home.navbar.principles')
        },
        {
          name: 'machinery',
          title: this.$t('home.navbar.machinery')
        }
      ],

      /** Navbar de contato */
      contactSelected: 'contact',
      optionsContact: [
        {
          name: 'contact',
          title: this.$t('home.contact.contactUs')
        },
        {
          name: 'budget',
          title: this.$t('home.contact.budget')
        }
      ],

      /** Maquinário */
      machinery: {
        gridColumns: [
          { name: 'description', alias: this.$t('home.machinery.description') },
          // { name: 'brand', alias: this.$t('home.machinery.brand') },
          // { name: 'capacity', alias: this.$t('home.machinery.capacity') }
        ],
        data: {
          'pt-BR': [
             {
              description: 'CENTRO DE USINAGEM',
              brand: '',
              capacity: '',
              highlighted: true
            },
            {
              description: 'Compressor de parafuso 20h a 100ph',
              capacity: ''
            },
            {
              description: 'Eletroerosão EDM 300A à 2000A',
              capacity: ''
            },
            {
              description: 'Fornos à óleo',
              capacity: ''
            },
            {
              description: 'Fornos de indução de 150 à 550Kva',
              capacity: ''
            },
            {
              description: 'FRESA',
              brand: '',
              capacity: '',
              highlighted: true
            },
            {
              description: 'Fresadora CNC',
              capacity: ''
            },
            {
              description: 'Fresadora ferramenteira',
              capacity: ''
            },
            {
              description: 'Fresadora universal',
              capacity: ''
            },
            {
              description: 'Jatos de granalha rotativo',
              capacity: ''
            },
            {
              description: 'Martelete 250kg pneumático',
              capacity: ''
            },
            {
              description: 'Martelo 3.500 a 12.000 libras',
              capacity: ''
            },
            {
              description: 'Prensa essêntrica de 60 a 500 toneladas',
              capacity: ''
            },
            {
              description: 'Prensa fricção de 80 à 650 toneladas',
              capacity: ''
            },
            {
              description: 'Torno diplomata, mecânico de 300 a 1500 de volteio',
              capacity: ''
            },
            {
              description: 'Torno IMOR passagem de 1.1/2mt',
              capacity: ''
            },
            // {
            //   description: 'FORJARIA',
            //   brand: '',
            //   capacity: '',
            //   highlighted: true
            // },
            // {
            //   description: 'Prensas Exentricas',
            //   capacity: '60 à 160 Toneladas'
            // },
            // {
            //   description: 'Prensas Fricção',
            //   brand: 'Grafita',
            //   capacity: '200 Toneladas'
            // },
            // {
            //   description: 'Prensas Fricção',
            //   brand: 'Gutmann',
            //   capacity: '250 Toneladas'
            // },
            // {
            //   description: 'Prensas Fricção',
            //   brand: 'Gutmann',
            //   capacity: '450 Toneladas'
            // },
            // {
            //   description: 'Prensas Fricção',
            //   capacity: '450 Toneladas'
            // },
            // {
            //   description: 'Prensas Fricção ',
            //   capacity: '650 Toneladas'
            // },
            // {
            //   description: 'Martelo Pneumático',
            //   brand: 'Huta Zygmunt',
            //   capacity: '3600 Libras',
            //   separated: true
            // },
            // {
            //   description: 'MATRIZARIA',
            //   highlighted: true
            // },
            // {
            //   description: '2 Fresas',
            //   brand: 'Sunlike'
            // },
            // {
            //   description: '2 Eletro-Erosão',
            //   brand: 'Engemaq'
            // },
            // {
            //   description: '2 Eletro-Erosão',
            //   brand: 'Resitron'
            // },
            // {
            //   description: 'Torno Mecânico',
            //   brand: 'Diplomat'
            // },
            // {
            //   description: 'Pantógrafo',
            //   brand: 'Pear',
            //   separated: true
            // },
            // {
            //   description: 'EXPEDIÇÃO',
            //   highlighted: true
            // },
            // {
            //   description: '2 Granalhas',
            //   brand: 'Rodojato'
            // },
            // {
            //   description: 'Ciclo Exaustor de Pó'
            // }
          ],
          'en-US': [
          {
              description: 'CENTRO DE USINAGEM',
              brand: '',
              capacity: '',
              highlighted: true
            },
            {
              description: 'Compressor de parafuso 20h a 100ph',
              capacity: ''
            },
            {
              description: 'Eletroerosão EDM 300A à 2000A',
              capacity: ''
            },
            {
              description: 'Fornos à óleo',
              capacity: ''
            },
            {
              description: 'Fornos de indução de 150 à 550Kva',
              capacity: ''
            },
            {
              description: 'FRESA',
              brand: '',
              capacity: '',
              highlighted: true
            },
            {
              description: 'Fresadora CNC',
              capacity: ''
            },
            {
              description: 'Fresadora ferramenteira',
              capacity: ''
            },
            {
              description: 'Fresadora universal',
              capacity: ''
            },
            {
              description: 'Jatos de granalha rotativo',
              capacity: ''
            },
            {
              description: 'Martelete 250kg pneumático',
              capacity: ''
            },
            {
              description: 'Martelo 3.500 a 12.000 libras',
              capacity: ''
            },
            {
              description: 'Prensa essêntrica de 60 a 500 toneladas',
              capacity: ''
            },
            {
              description: 'Prensa fricção de 80 à 650 toneladas',
              capacity: ''
            },
            {
              description: 'Torno diplomata, mecânico de 300 a 1500 de volteio',
              capacity: ''
            },
            {
              description: 'Torno IMOR passagem de 1.1/2mt',
              capacity: ''
            },
            // {
            //   description: 'FORGERING',
            //   brand: '',
            //   capacity: '',
            //   highlighted: true
            // },
            // {
            //   description: 'Eccentric Presses',
            //   capacity: '60 to 160 Tons'
            // },
            // {
            //   description: 'Friction Presses',
            //   brand: 'Graphite',
            //   capacity: '200 Tons'
            // },
            // {
            //   description: 'Friction Presses',
            //   brand: 'Gutmann',
            //   capacity: '250 Tons'
            // },
            // {
            //   description: 'Friction Presses',
            //   brand: 'Gutmann',
            //   capacity: '450 Tons'
            // },
            // {
            //   description: 'Friction Presses',
            //   capacity: '450 Tons'
            // },
            // {
            //   description: 'Prensas Fricção ',
            //   capacity: '650 Tons'
            // },
            // {
            //   description: 'Pneumatic Hammer',
            //   brand: 'Huta Zygmunt',
            //   capacity: '3600 Pounds',
            //   separated: true
            // },
            // {
            //   description: 'MATERIALS',
            //   highlighted: true
            // },
            // {
            //   description: '2 milling cutters',
            //   brand: 'Sunlike'
            // },
            // {
            //   description: '2 Electro-Erosion',
            //   brand: 'Engemaq'
            // },
            // {
            //   description: '2 Electro-Erosion',
            //   brand: 'Resitron'
            // },
            // {
            //   description: 'Lathe',
            //   brand: 'Diplomat'
            // },
            // {
            //   description: 'Pantograph',
            //   brand: 'Pear',
            //   separated: true
            // },
            // {
            //   description: 'EXPEDITION',
            //   highlighted: true
            // },
            // {
            //   description: '2 grit',
            //   brand: 'Rodojet'
            // },
            // {
            //   description: 'Dust Exhausting Cycle'
            // }
          ]
        }
      },

      /** Certificado */
      imgCertificate: imgCertificate,

      /** Sobre nós (modal) */
      aboutUs: false,
      aboutUsDescription: {
        'pt-BR': `<p>O nosso forjamento é um dos meios mais antigos de se trabalhar com aço e com o processo de forno à óleo. São realizados golpes em alavancas feitos conforme o aço, matriz e diâmetro da peça; utilizamos prensas (fricção e excêntrica) de 100 toneladas à 600 toneladas, martelo 5.000 libras à 12.000 libras, onde em conjunto com a temperatura correta do aço e a matriz preparada para a peça, dão forma a peça final. O nosso forjamento é parabenizado em excelência por cumprir a norma DIN8583, que define o processo de conformação com a temperatura do trabalho.</p>
          <p>Nos últimos anos iniciamos o forjamento fornos de indução, onde a qualidade final da peça é de extrema qualidade, juntamente com a nossa matrizaria que tem o Centro de Usinagem para complementar o produto final ao cliente.</p>
          <p>As peças que são forjadas podem variar com o peso, desde de 200g até de grandes 100kg.</p>
          <p>Trabalhamos em geral com metais que podem ser forjados:</p>
          <ul>
            <li>Carbono</li>
            <li>Aços inoxidáveis</li>
            <li>Aço 1020, 1045, 8620, 4140</li>
            <li>Alumínio</li>
            <li>Latão e cobre</li>
          </ul>
          <p>Nossa empresa oferece a melhor qualidade, pontualidade e atendimento desde o primeiro contato com o cliente até o final, disponibilizando 4 representantes que atendem todo o RS, SC, SP e RJ.</p>
          <p>Nosso negócio busca mostrar os possíveis caminhos para alcançar a sua meta da melhor forma. Sempre a satisfação do cliente em primeiro lugar.</p>
          <p>Onde nossa equipe de colaboradores e seus respectivos setores empreendendo em diversas áreas, com ferramentas digitais inteligentes e otimizar um ótimo resultado em todos os setores.</p>`,
        'en-US': `<p>Our forging is one of the oldest ways of working with steel and using the oil furnace process. Blows are performed on levers made according to the steel, matrix and diameter of the piece; we use presses (friction and eccentric) from 100 tons to 600 tons, hammer 5,000 pounds to 12,000 pounds, where together with the correct temperature of the steel and the matrix prepared for the part, they shape the final part. Our forging is highly praised for complying with the DIN8583 standard, which defines the forming process with the working temperature.</p>
          <p>In recent years, we started forging induction furnaces, where the final quality of the piece is extremely high, together with our die shop, which has a Machining Center to complement the final product for the customer.</p>
          <p>The pieces that are forged can vary in weight, from 200g to a large 100kg.</p>
          <p>We generally work with metals that can be forged:</p>
          <ul>
            <li>Carbon</li>
            <li>Stainless steels</li>
            <li>Steel 1020, 1045, 8620, 4140</li>
            <li>Aluminum</li>
            <li>Brass and copper</li>
          </ul>
          <p>Our company offers the best quality, punctuality and service from the first contact with the customer until the end, providing 4 representatives who serve all of RS, SC, SP and RJ.</p>
          <p>Our business seeks to show the possible ways to reach your goal in the best way. Always customer satisfaction first.</p>
          <p>Where our team of collaborators and their respective sectors undertake in different areas, with intelligent digital tools and optimize a great result in all sectors.</p>`
      },

      /** Visibilidade da modal de produtos */
      productions: {
        visible: false,
        image: '',
        title: '',
        subtitle: '',
        titleDescription: this.$t('home.products.detais'),
        description: ''
      },

      /** Formulário de contato */
      contact: {
        name: null,
        email: null,
        department: null,
        subject: null,
        description: '',
        departments: []
      },

      /** Formulário de orçamento */
      budget: {
        name: null,
        email: null,
        representative: null,
        representatives: [],
        products: [
          {
            name: '',
            amount: ''
          }
        ],
        subject: null,
        description: ''
      },

      /** Confirmação de contato: Fale conosco */
      successContact: false,

      /** Confirmação de contato: Orçamento */
      successBudget: false,

      /** Categorias */
      categories: [],

      /** Produtos */
      products: [],

      news: [],

      partner:[],

      /** Se os produtos foram carregados */
      loadedProducts: false,

      /** Se as notícias foram carregadas */
      loadedNews: false

    }
  },

  watch: {
    '$i18n.locale': function() {
      this.options[0].title = this.$t('home.navbar.principles')
      this.options[1].title = this.$t('home.navbar.machinery')
      this.optionsContact[0].title = this.$t('home.contact.contactUs')
      this.optionsContact[1].title = this.$t('home.contact.budget')
      this.machinery.gridColumns[0].alias = this.$t(
        'home.machinery.description'
      )
      this.machinery.gridColumns[1].alias = this.$t('home.machinery.brand')
      // this.machinery.gridColumns[2].alias = this.$t('home.machinery.capacity')
       this.productions.titleDescription = this.$t('home.products.detais')
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.addEventListener('resize', this.onResize)
    })
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.onResize)
  },

  computed: {
    perPageCarousel() {
      // return this.windowWidth > 768 ? 3 : 1
      return this.windowWidth > 768 ? 5 : 1
    },

    /** Verificar se o botão de envio de contato deve ser habilitado */
    disabledContact() {
      return (
        this.contact.name === null ||
        this.contact.email === null ||
        this.contact.department === null ||
        this.contact.subject === null ||
        this.contact.description === ''
      )
    },

    /** Verificar se o botão de envio de orçamento deve ser habilitado */
    disabledBudget() {
      return (
        this.budget.name === null ||
        this.budget.email === null ||
        this.budget.representative === null ||
        this.budget.products[0].name === '' ||
        this.budget.products[0].amount === '' ||
        this.budget.subject === null ||
        this.budget.description === ''
      )
    }
  },

  created() {
    /** Buscar as categorias */
    this.getCategories()

    /** Buscar os produtos */
    // this.getProducts()

    /** Buscar os departamentos */
    this.getDepartaments()

    /** Buscar os representantes */
    this.getRepresentatives()

    this.getNews()
  },

  methods: {
    /** Buscar as categorias */
    getCategories() {
      this.$categories
        .getAll()
        .then(async (response) => {
          // this.categories = response.data
          await response.data.forEach(async (category) => {
            const res = await this.$products
            .getByCategoria(category.categoriaId)
            category.products = res.data
            this.categories.push(category)
          })
          /** Mostrar os slides */
          this.loadedProducts = true
        })
        .catch(() =>{          
          this.$toast.warning(this.$t('home.error.get-categories'))
        })
    },

    /** Buscar as notícias */
    getNews(){
      // this.loadedNews = true
      // this.$news
      //   .getAll()
      //   .then((response) => {
      //     const newsList = response.data.map((news) => {
      //       return {
      //         id: news.noticiaId,
      //         title: news.titulo,
      //         description: news.mensagem,
      //         image: news.imagem,
      //         video: news.video,
      //         status: news.ativo
      //       }
      //     })
      //     this.news = newsList
      //   })
      //   .catch(() => {
      //     this.$toast.warning('Houve um erro ao apresentar as produtos.')
      //   })
    },

    /** Buscar os departamentos */
    getDepartaments() {
      this.$departaments
        .getAll()
        .then((response) => {
          this.contact.departments = response.data.map((item) => {
            item.name = item.descricao

            return item
          })
        })
        .catch(function() {
          this.$toast.warning(this.$t('home.error.get-departaments'))
        })
    },

    /** Buscar os representantes */
    getRepresentatives() {
      this.$representatives
        .getAll()
        .then((response) => {
          this.budget.representatives = response.data.map((item) => {
            item.name = item.nome

            return item
          })
        })
        .catch(function() {
          this.$toast.warning(this.$t('home.error.get-representatives'))
        })
    },

    /** Buscar os produtos */
    getProducts() {
      this.$products
        .getAll()
        .then((response) => {
          this.products = response.data
          /** Mesclar categorias e produtos */
          this.categories.map((category) => {
            category.products = this.products.filter(
              (product) => category.categoriaId === product.categoriaId
            )
          })
          /** Mostrar os slides */
          this.loadedProducts = true
        })
        .catch(function() {
          this.$toast.warning(this.$t('home.error.get-products'))
        })
    },

    /** Abrir modal de produtos
     * @param { Object } production - Objeto de produtos
     */
    openModalProductions(production) {
      this.productions.visible = true
      this.productions.image = production.imagem
      this.productions.descriptionProduct = production.descricao
      this.productions.title = production.titulo
      this.productions.subtitle = production.subTitulo
    },

    /** Adicionar produtos ao formulário */
    addProducts() {
      this.budget.products.push({
        name: '',
        amount: ''
      })
    },

    /** Remover produtos do formulário pelo index
     * @params {Number} index - Posição do produto no array
     */
    removeProducts(index) {
      this.budget.products.splice(index, 1)
    },

    /** Detectar ao redefinir a largura da tela */
    onResize() {
      this.windowWidth = window.innerWidth
    },

    /** Enviar e-mail de contato */
    sendEmailContact() {
      const message = `
        Nome: ${this.contact.name}\n
        E-mail: ${this.contact.email}\n
        Departamento: ${
          this.contact.department ? this.contact.department.descricao : null
        }\n
        Descrição: ${this.contact.description}
      `
      /** Enviar e-mail de d */
      this.$email
        .contact({
          assunto: this.contact.subject,
          mensagem: message,
          destinatario: this.contact.department
            ? this.contact.department.email
            : null
        })
        .then(() => {
          /** Feedback de sucesso */
          this.successContact = true

          /** Limpar os dados */
          this.contact.name = null
          this.contact.email = null
          this.contact.department = null
          this.contact.subject = null
          this.contact.description = ''
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error(this.$t('home.error.contact-email'))
        })
    },

    /** Enviar e-mail de contato para orçamento */
    sendEmailBudget() {
      let products = ''
      for (let i = 0; i < this.budget.products.length; i++) {
        if (
          this.budget.products[i].name !== '' &&
          this.budget.products[i].amount !== ''
        ) {
          products += `${this.budget.products[i].name} (${this.budget.products[i].amount}) `
        }
      }

      const message = `
        Nome: ${this.budget.name}\n
        E-mail: ${this.budget.email}\n
        Representante: ${
          this.budget.representative ? this.budget.representative.name : null
        }\n
        Produtos: ${products}\n
        Descrição: ${this.budget.description}
      `
      /** Enviar e-mail de d */
      this.$email
        .contact({
          assunto: this.budget.subject,
          mensagem: message,
          destinatario: this.budget.representative
            ? this.budget.representative.email
            : null
        })
        .then(() => {
          /** Feedback de sucesso */
          this.successBudget = true

          /** Limpar os dados */
          this.budget.name = null
          this.budget.email = null
          this.budget.representative = null
          this.budget.products = [
            {
              name: '',
              amount: ''
            }
          ]
          this.budget.subject = null
          this.budget.description = ''
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error(this.$t('home.error.budget-email'))
        })
    }
  }
}
</script>

<style lang="scss" scoped>
.info-box {
  display: flex;
  align-items: center;
  width: 100%;

  @media screen and (max-width: 768px) {
    flex-direction: column;
  }

  &__fill {
    background: $primary;

    .paragraph,
    .heading {
      color: $white;
    }
  }

  &--desc {
    width: 100%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 768px) {
      display: block;
    }

    &__right {
      justify-content: start;
    }

    &__gray {
      background: $background;
    }
  }

  &--paragraph {
    line-height: 28px;
    margin-bottom: 24px;

    &__spacing {
      margin-bottom: 30px;

      @media screen and (max-width: 768px) {
        margin-bottom: 20px;
      }
    }

    &__white {
      color: $white;
    }
  }

  &--container {
    max-width: 380px;
    margin: 100px 150px 70px 0px;

    @media screen and (max-width: 768px) {
      margin: 40px 20px;
    }
  }

  &--image {
    width: 100%;
    position: relative;
    align-self: stretch;
    overflow: hidden;

    @media screen and (max-width: 768px) {
      display: none;
    }

    img {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      min-height: 100%;
      min-width: 100%;
      max-width: 150%;
    }
  }
}

.title {
  &__with-border-bottom {
    display: inline-block;
    border-bottom: 8px solid $orange;
    margin-bottom: 72px;

    @media screen and (max-width: 768px) {
      margin-bottom: 30px;
    }
  }
  &__with-border-top {
    display: inline-block;
    border-top: 4px solid $white;
    line-height: 38px;
    margin-bottom: 0px;
  }
}

.box {
  padding: 72px 0px 100px 0px;

  @media screen and (max-width: 768px) {
    padding: 40px 20px;
  }

  &__fill {
    background: $background;
  }

  &--content {
    max-width: 1089px;
    margin: 0px auto;
  }
}
.news-bo{
  height: 400px;
  overflow: auto;
}

.slide {
  &__multiple {
    cursor: pointer;
    max-width: 311px;
    div {
      background: $gray;
      padding: 24px;
    }
  }

  &--image {
    max-width: 100%;
    margin: 0px auto 24px auto;
  }

  &--label {
    color: $secondary;
    text-align: center;
  }
}

.contact {
  &--whatsapp-icon {
    margin: 5px 0px;
    display: block;
    color: #44c054;
  }

  &--row {
    display: flex;
    gap: 136px;
    align-items: flex-start;

    @media screen and (max-width: 768px) {
      flex-direction: column;
      gap: 40px;
    }
  }

  &--item {
    width: 100%;
  }

  &__info {
    position: relative;
    background: $gray;
    padding: 90px 90px;

    @media screen and (max-width: 768px) {
      padding: 20px;
    }

    div {
      margin-bottom: 64px;

      @media screen and (max-width: 768px) {
        margin-bottom: 30px;
      }

      &:last-of-type {
        margin-bottom: 0px;
      }
    }

    &--title {
      color: $primary;
      line-height: 23px;
      margin-bottom: 0px;
    }

    &--paragraph {
      line-height: 23px;
      margin-bottom: 0px;
    }

    &:before {
      content: '';
      position: absolute !important;
      top: 0px;
      left: 100%;
      width: 5000px;
      height: 100%;
      background: $gray;

      @media screen and (max-width: 768px) {
        display: none;
      }
    }
  }

  &__form {
    ::v-deep {
      .navbar--item {
        overflow: hidden;
      }
    }

    &--row {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 24px;
      margin-bottom: 32px;

      .form-input {
        margin-bottom: 0px;
      }
    }

    &--amount {
      max-width: 152px;
    }

    &--add {
      color: $secondary;
      font-size: 18px;
      border: 2px solid $secondary;
      border-radius: 100%;
      width: 18px;
      height: 18px;
      min-width: 18px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 900;
      cursor: pointer;

      &__remove {
        transform: rotate(45deg);
      }
    }
  }
}
</style>
