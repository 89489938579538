<template>
  <div class="home">
    <AdminLayout page="Início">
      <template #content></template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'

export default {
  name: 'Admin',
  components: {
    AdminLayout
  }
}
</script>

<style lang="scss" scoped>
.home {
  ::v-deep {
    .admin-layout--container {
      padding: 0px;
    }
  }
}
</style>
