<template>
  <div class="home">
    <AdminLayout page="Noticias">
      <template #content>
        <!-- TÍTULO -->
        <Heading size="5" weight="bold" class="title">Cadastrar</Heading>

        <!-- FORMULÁRIO DE CADASTRO DE NOTICIAS -->
        <div class="form">
         Título
                 <!-- CAMPO DA DESCRIÇÃO -->
                 <input
            v-model="title"
            :admin="true"
            contenteditable="true"
            label="Mensagem da notícia"
            class="form--input mb-3"
            />
            
<br>
          <div>
          
        <!-- <Editor></Editor> -->
        <vue-editor v-model="message"></vue-editor>
      </div>

   

          <!-- BOTÃO DE ENVIAR -->
          <Button
            :radius="false"
            class="form--button ml-auto d-block"
            @click="createProduct()"
          >
            CADASTRAR
          </Button>
        </div>
        
        
        
        <!-- TABELA -->
        <DatatableAdmin
          :colsUpper="datatable.gridColumnsUpper"
          :rows="datatable.data"
          :numberElements="datatable.data.length"
          :page="datatable.page"
          title="Notícias cadastradas"
          termResults="resultados encontrados"
          class="datatable"
        >
          <!-- STATUS -->
          <template v-slot:status="slotProps">
            <Paragraph size="60" weight="normal">
              {{ slotProps.value ? 'Ativo' : 'Inativo' }}
            </Paragraph>
          </template>

          <!-- COLUNA DE AÇÕES -->
          <template v-slot:action="slotProps">
            <div class="datatable--actions">
              <!-- EDITAR CATEGORIA -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-pen-to-square"
                @click="edit(slotProps.row.id)"
              />

              <!-- REMOVER CATEGORIAS -->
              <font-awesome-icon
                class="datatable--icon"
                icon="fa-solid fa-trash-can"
                @click="dialogCorpus(slotProps.row.id)"
              />
            </div>
          </template>
        </DatatableAdmin>

        <!-- EDITAR PRODUTO -->
        <ModalAdmin
          v-model="product.edit"
          title="EDITAR PRODUTO"
          class="modal-edit__edit"
        >
          <img v-if="product.image" :src="product.image" class="form--image" />

          <div class="form--row">
            <!-- CAMPO DO NOME -->
            <FormInput
              v-model="product.title"
              :admin="true"
              label="Título do produto"
              class="form--input"
            />

            <!-- CAMPO DO SUBTITULO -->
            <FormInput
              v-model="product.subtitle"
              :admin="true"
              label="Subtítulo do produto"
              class="form--input"
            />
          </div>

          <div class="form--row">

            <!-- CAMPO DE VALOR -->
            <FormInput
              v-model="product.value"
              :admin="true"
              type="number"
              label="Valor"
              class="form--input"
            />

            <!-- CAMPO DA IMAGEM -->
            <FormInputFile
              v-model="product.changeImage"
              :admin="true"
              label="Alterar a imagem"
              class="form--input"
            />
          </div>

          <!-- CAMPO DA DESCRIÇÃO -->
          <Textarea
            v-model="product.description"
            :admin="true"
            label="Descrição do produto"
            class="form--input mb-3"
          />

          <div class="modal-edit--row">
            <!-- BOTÃO DE SALVAR -->
            <Button
              :radius="false"
              class="modal-edit--button"
              @click="saveProducts()"
            >
              GRAVAR
            </Button>
          </div>
        </ModalAdmin>

        <!-- MODAL DE CONFIRMAÇÃO DE REMOÇÃO DE NOTÍCIAS -->
        <ModalAdmin
          v-model="product.delete"
          title="REMOVER NOTÍCIAS"
          class="modal-edit__remove"
        >
          <Paragraph size="60" weight="normal">
            Tem certeza que deseja remover o produto:
            <strong>{{ product.title }}?</strong>
          </Paragraph>

          <div class="modal-edit--row">
            <!-- BOTÃO DE CANCELAR -->
            <Button
              :radius="false"
              color="outline"
              class="modal-edit--button"
              @click="closeModalProduct()"
            >
              CANCELAR
            </Button>

            <!-- BOTÃO DE DELETAR -->
            <Button
              :radius="false"
              color="danger"
              class="modal-edit--button"
              @click="deleteCategory()"
            >
              DELETAR
            </Button>
          </div>
        </ModalAdmin>
      </template>
    </AdminLayout>
  </div>
</template>

<script>
import AdminLayout from '@/layouts/AdminLayout'
import FormInput from '@/components/FormInput'

import FormInputFile from '@/components/FormInputFile'
import Button from '@/components/Button'
import DatatableAdmin from '@/components/DatatableAdmin'
import Heading from '@/components/Heading'
import ModalAdmin from '@/components/ModalAdmin'
import Textarea from '@/components/Textarea'
// import Editor from '@/components/Editor'
import { VueEditor } from 'vue2-editor'

export default {
  name: 'Products',

  components: {
    AdminLayout,
    Heading,
    FormInput,
    
    FormInputFile,
    Button,
    DatatableAdmin,
    ModalAdmin,
    Textarea,
    // Editor,
    VueEditor,
  },

  data() {
    return {
      /** Título da noticia */
      title: null,

      /** Imagem da noticia */
      image: null,

      /** Video da noticia */
      video: null,

      /** Status da noticia */
      status: true,

      /** Mensagem da noticia */
      message: '',

      /** Tabela */
      datatable: {
        gridColumnsUpper: [
          { name: 'title', alias: 'Notícia' },
          { name: 'action', alias: 'Ação' }
        ],

        search: null,

        data: []
      },

      /** Dados da modal */
      modal: {
        id: null,
        title: null
      },

      /** Categoria a ser editado */
      product: {
        id: null /** ID do produto */,
        title: null /** Título do produto */,
        subtitle: null /** Subtítulo do produto */,
        description: null /** Status do produto */,
        value: null /** Valor do produto */,
        image: null /** Imagem do produto */,
        changeImage: null /** Imagem alterada */,
        category: null /** Categoria do produto */,
        edit: false /** Visibilidade da modal de edição */,
        delete: false /** Visibilidade da modal de remoção */
      }
    }
  },

  async created() {
    /** Buscar as noticias */
    await this.getNews()
  },

  methods: {
    /** Buscar as notícias */
    getNews() {
      this.$news
        .getAll()
        .then((response) => {
          const newsList = response.data.map((news) => {
            return {
              id: news.noticiaId,
              title: news.titulo,
              description: news.mensagem,
              image: news.imagem,
              video: news.video,
              status: news.ativo
            }
          })
          this.datatable.data = newsList
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao apresentar as produtos.')
        })
    },

    /** Criar categoria */
    async createProduct() {      
      /** Converter a imagem para base64 */
      // const image = await this.toBase64(this.image)

      /** Salvar produto */
      this.$news
        .create({
          titulo: this.title,
        //  subTitulo: this.subtitle,
        mensagem: this.message,
          // descricao: this.description,
          // imagem: image,
          // valor: parseInt(this.value),
          // categoriaId: this.category?.id,          
          ativo: true
        })
        .then(() => {
          /** Feedback de sucesso */
          this.$toast.success('Notícia criada com sucesso!')
        })
        .catch(() => {
          /** Feedback de erro */
          this.$toast.error('Houve um erro ao criar o produto!')
        })
        .finally(() => {
          /** Limpar o campo do nome */
          this.title = null
          this.subtitle = null
          this.description = ''
          this.value = null
          this.image = null
          this.category = null
          /** Recarregar a lista */
          this.getProducts()
        })
    },

    /** Exibir a modal de edição do produto
     * @param {Number} id: Id do produto
     */
    edit(id) {
      /** Localizar o produto na listagem */
      const product = this.datatable.data.find((item) => item.id === id)

      /** Pegar os dados do produto e abrir a modal */
      if (product) {
        this.product.edit = true
        this.product.id = product.id
        this.product.title = product.title
        this.product.subtitle = product.subtitle
        this.product.description = product.description ?? ''
        this.product.value = product.value
        this.product.image = product.image
      } else {
        this.$toast.warning('Houve um erro ao encontrar o produto.')
        this.closeModalProduct()
      }
    },

    /** Confirmar se deseja remover o produto
     * @param {Number} id: Id do produto
     */
    dialogCorpus(id) {
      /** Localizar o produto na listagem */
      const product = this.datatable.data.find((item) => item.id === id)
      /** Pegar os dados do produto e abrir a modal de confirmação */
      if (product) {
        this.product.delete = true
        this.product.id = product.id
        this.product.title = product.title
      } else {
        this.$toast.warning('Houve um erro ao encontrar a produto.')
        this.closeModalProduct()
      }
    },

    /** Fechar a modal de edição de produto */
    closeModalProduct() {
      this.modal.id = null
      this.modal.title = null

      this.product.id = null
      this.product.title = null
      this.product.subtitle = null
      this.product.description = null
      this.product.value = null
      this.product.image = null
      this.product.changeImage = null
      this.product.category = null
      this.product.edit = false
      this.product.delete = false
    },

    /** Buscar as produtos */
    async saveProducts() {
      /** Converter a imagem para base64 */
      let image = null
      if (this.product.changeImage !== null)
        image = await this.toBase64(this.product.changeImage)

      this.$products
        .update({
          produtoId: this.product.id,
          categoriaId: this.product.category?.id,
          titulo: this.product.title,
          subTitulo: this.product.subtitle,
          descricao: this.product.description,
          imagem: image ?? this.product.image,
          valor: parseInt(this.product.value),
          ativo: true
        })
        .then(() => {
          this.$toast.success('Produto atualizado com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao atualizar a produto.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalProduct()
          /** Recarregar a listagem */
          this.getProducts()
        })
    },

    /** Remover o produto */
    deleteCategory() {
      this.$news
        .delete(this.product.id)
        .then(() => {
          this.$toast.success('Produto removido com sucesso!')
        })
        .catch(() => {
          this.$toast.warning('Houve um erro ao remover o produto.')
        })
        .finally(() => {
          /** Fechar a modal */
          this.closeModalProduct()

          setTimeout(() => {
            /** Recarregar a listagem */
            this.getProducts()
          }, 1000)
        })
    },

    /** Converter imagem para base64 */
    toBase64(image) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.onload = () => {
          resolve(reader.result)
        }
        reader.onerror = reject
        reader.readAsDataURL(image)
      })
    }
  }
}

</script>

<style lang="scss" scoped>
.title {
  margin-bottom: 20px;
}
.form {
  background: $gray;
  padding: 7px;
  border-radius: 5px;
  gap: 7px;
  margin-bottom: 25px;

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  &--input {
    margin: 0px;
  }

  &--image {
    display: block;
    margin: 0px auto 20px auto;
  }

  &--group-radio {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 20px;
  }

  &--button {
    margin: 0px;
  }
}

.list-files {
  padding-left: 15px;
  margin-bottom: 10px;

  &--item {
    line-height: 20px;
  }
}

.datatable {
  &--link {
    cursor: pointer;
    text-decoration: underline;
  }

  ::v-deep {
    thead th:nth-child(4),
    tr td:nth-child(4) {
      width: 120px;
      text-align: center;
    }
  }

  &--actions {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
  }

  &--icon {
    color: $primary;
    text-align: center;
    cursor: pointer;
  }
}

/** MODAL DE EDIÇÃO DO CATEGORIAS */
.modal-edit {
  ::v-deep {
    .paragraph {
      line-height: 20px;
    }
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: flex-end;
    margin-top: 20px;
  }

  &--button {
    display: block;
  }

  &__edit {
    ::v-deep {
      .modal-component--content {
        max-width: 1080px;
      }
    }
  }

  &__remove {
    ::v-deep {
      .modal-component--content {
        max-width: 500px;
      }
    }
  }
}
</style>
