export default ($axios) => ({
  /** Cria um produto
   * @param { String } titulo: Título do produto
   * @param { String } subTitulo: Subtítulo do produto
   * @param { String } descricao: Descrição do produto
   * @param { String|Number } valor: Valor do produto
   * @param { String } imagem: Imagem do produto em formato base64
   * @param { String|Number } categoriaId: Id da categoria vinculada
   * @param { Boolean } ativo: Status do produto
   */
  create: (data) =>
    $axios.post('/produto/post', {
      ...data
    }),

  /** Editar um produto
   * @param { String } produtoId: Id do produto
   * @param { String } categoriaId: Id da categoria
   * @param { String } titulo: Título do produto
   * @param { String } subTitulo: Subtítulo do produto
   * @param { String } descricao: Descrição do produto
   * @param { String|Number } valor: Valor do produto
   * @param { String } imagem: Imagem do produto em formato base64
   * @param { String|Number } categoriaId: Id da categoria vinculada
   * @param { Boolean } ativo: Status do produto
   */
  update: (data) =>
    $axios.put('/produto/put', {
      ...data
    }),

  /** Busca os produtos */
  getAll: () => $axios.get('/produto/getAll'),

   getByCategoria: async (categoriaId = '') => await $axios.get(`/produto/getByCategoria?categoriaId=${categoriaId}`),

  /** Remove um produto */
  delete: (id = '') => $axios.put(`/produto/delete?id=${id}`)
})
