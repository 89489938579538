<template>
  <div class="header" id="header">
    <div class="header--row">
      <!-- LOGO -->
      <img :src="logo" :alt="$t('home.title')" class="header--logo" />
      <!-- LINKS -->
      <ul class="links links__desktop">
        <li class="links--item" @click="goTo('header')">
          <Paragraph weight="bold" class="links--item links--item__active">
            {{ $t('home.menu.home') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('who-we-are')">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.whoWeAre') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="newsModal = !newsModal">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.news') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('products')">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.products') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('contact')">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.contact') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('representatives')">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.representatives') }}
          </Paragraph>
        </li>
        <li class="links--item"  @click="partnerModal = !partnerModal">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.partners') }}
          </Paragraph>
        </li>
      </ul>
      <!-- TROCA DE IDIOMAS -->
      <LanguageFlags />
      <!-- BOTÃO DE MENU COLAPSADO -->
      <div
        :class="{ 'header--button__active': menu }"
        class="header--button"
        @click="menu = !menu"
      >
        <!-- ÍCONE DE ABRIR -->
        <font-awesome-icon v-if="!menu" icon="fa-solid fa-bars" />
        <!-- ÍCONE DE FECHAR -->
        <font-awesome-icon v-if="menu" icon="fa-solid fa-times" />
      </div>
    </div>
    <!-- MENU COLAPSADO -->
    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <ul v-if="menu" class="links links__mobile">
        <li class="links--item" @click="goTo('header')">
          <Paragraph
            size="100"
            weight="bold"
            class="links--item links--item__active"
          >
            {{ $t('home.menu.home') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('who-we-are')">
          <Paragraph size="100" weight="bold" class="links--item">
            {{ $t('home.menu.whoWeAre') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('representatives')">
          <Paragraph size="100" weight="bold" class="links--item">
            {{ $t('home.menu.representatives') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('products')">
          <Paragraph size="100" weight="bold" class="links--item">
            {{ $t('home.menu.products') }}
          </Paragraph>
        </li>
        <li class="links--item" @click="goTo('contact')">
          <Paragraph size="100" weight="bold" class="links--item">
            {{ $t('home.menu.contact') }}
          </Paragraph>
        </li>
        <li class="links--item"  @click="partnerModal = !partnerModal">
          <Paragraph weight="bold" class="links--item">
            {{ $t('home.menu.partner') }}
          </Paragraph>
        </li>
      </ul>
    </transition>

    <ModalPartner
          v-model="partnerModal"          
        ></ModalPartner>

        <ModalNews
          v-model="newsModal"          
        ></ModalNews>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'
import LanguageFlags from '@/components/LanguageFlags'
import logo from '@/assets/images/logo.png'
import ModalPartner from '@/components/Modal/ModalPartner.vue'
import ModalNews from '@/components/Modal/ModalNews.vue'

export default {
  name: 'Header',
  components: {
    Paragraph,
    LanguageFlags,
    ModalPartner,
    ModalNews,
  },
  data() {
    return {
      /** Imagem do logo */
      logo: logo,
      /** Visibilidade do menu mobile */
      menu: false,
      partnerModal: false,
      newsModal: false
    }
  },
  methods: {
    /** Rolar até a seção pelo ID
     * @param {String} id - ID do elemento
     */
    goTo(id) {
      window.scrollTo({
        top: document.getElementById(id).offsetTop,
        left: 0,
        behavior: 'smooth'
      })
    },

    /** Controla a trasição ao abrir o accordion */
    start(el) {
      el.style.height = el.scrollHeight + 'px'
    },

    /** Controla a trasição ao fechar o accordion */
    end(el) {
      el.style.height = ''
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  height: auto;
  width: 100%;
  background: $white;
  position: relative;
  padding: 5px 24px;

  @media screen and (max-width: 768px) {
    padding: 5px 20px;
  }

  &--logo {
    height: 64px;
    margin-right: auto;
  }

  &--row {
    display: flex;
    align-items: center;
    gap: 15px;
    max-width: 1500px;
    margin: 0px auto;
  }

  &--button {
    border: 1px solid $secondary;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: $secondary;
    transition: 0.2s all;

    @media screen and (min-width: 768px) {
      display: none;
    }

    &__active {
      border-color: $primary;
      color: $primary;
    }
  }
}

.links {
  display: flex;
  align-items: center;
  gap: 40px;
  margin: 0px 80px;

  &__desktop {
    @media screen and (max-width: 768px) {
      display: none;
    }
  }

  &__mobile {
    position: absolute;
    top: 100%;
    left: 0px;
    width: 100%;
    z-index: 100;
    background: $white;
    margin: 0px;
    padding: 30px 0px;
    flex-direction: column;
    gap: 30px;

    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &--item {
    cursor: pointer;

    &__active {
      color: $orange;
      border-bottom: 2px solid $orange;
    }
  }
}

.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
