export default ($axios) => ({
  /** Cria um representante
   * @param { Object } data: "Nome", "Email", "UF", "Telefone" para criar um representante
   */
  create: (data) =>
    $axios.post('/representante/post', {
      ...data
    }),

  /** Atualizar um representante
   * @param { Number } id: "representanteId" da representante
   * @param { Object } data: "Nome", "Email", "UF", "Telefone"
   */
  update: (data) =>
    $axios.put('/representante/put', {
      ...data
    }),

  /** Busca as representantes */
  getAll: () => $axios.get('/representante/getall'),

  /** Remove um representante */
  delete: (id = '') => $axios.put(`/representante/delete?id=${id}`)
})
