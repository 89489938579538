/** Dicionário de termos da tela inicial */

export const home = {
  title: 'RS FORJARIA',
  description1: `Founded in 2001 in the city of Taquara, it began its activities
  with only 2 employees, and currently has about 40
  contributors.`,
  whoWeAre: 'Who we are',
  representatives: 'Representatives',
  knowMore: 'Know more',
  aboutUs:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum tellus elit sed risus. Maecenas eget condimentum velit, sit amet feugiat lectus. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex. Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus. Curabitur tempor quis eros tempus lacinia. Nam bibendum pellentesque quam a convallis. Sed ut vulputate nisi. Integer in felis sed leo vestibulum venenatis. Suspendisse quis arcu sem. Aenean feugiat ex eu vestibulum vestibulum. Morbi a eleifend magna. Nam metus lacus, porttitor eu mauris a, blandit ultrices nibh. Mauris sit amet magna non ligula vestibulum eleifend. Nulla varius volutpat turpis sed lacinia. Nam eget mi in purus lobortis eleifend. Sed nec ante dictum sem condimentum ullamcorper quis venenatis nisi. Nam pulvinar blandit velit, id condimentum diam faucibus at. Aliquam lacus nisi, sollicitudin at nisi nec, fermentum congue felis. Quisque mauris dolor, fringilla sed tincidunt ac, finibus non odio. Sed vitae mauris nec ante pretium finibus. Donec nisl neque, pharetra ac elit eu, faucibus aliquam ligula. Nullam dictum, tellus tincidunt tempor laoreet, nibh elit sollicitudin felis, eget feugiat sapien diam nec nisl. Aenean gravida turpis nisi, consequat dictum risus dapibus a. Duis felis ante, varius in neque eu, tempor suscipit sem. Maecenas ullamcorper gravida sem sit amet cursus. Etiam pulvinar purus vitae justo pharetra consequat. Mauris id mi ut arcu feugiat maximus. Mauris consequat tellus id tempus aliquet.',
  description2: `Our expansion is the result of our team's commitment to conquer
  the trust of our customers and suppliers.`,
  description3: 'We will always continue to seek improvement.',
  labelTitle1: 'Own building',
  labelDescription1: 'Production area of ​​20,000m²',
  labelTitle2: 'Forging with die',
  labelDescription2: 'Greater agility in service',
  labelTitle3: 'Trust',
  labelDescription3: 'Professionalism from start to finish',

  /** BOTÕES DO MENU */
  menu: {
    home: 'HOME',
    whoWeAre: 'WHO WE ARE',
    representatives: 'REPRESENTATIVES',
    products: 'PRODUCTS',
    contact: 'CONTACT'
  },

  navbar: {
    principles: 'Our principles',
    machinery: 'Machinery',
    certification: 'Certification'
  },

  /** Produtos */
  products: {
    description: 'What are we doing new for you, catch up below',
    detais: 'Product Details'
  },

  /** Maquinário */
  machinery: {
    description: 'Description',
    brand: 'Brand',
    capacity: 'Capacity'
  },

  /** Contato */
  contact: {
    contactUs: 'Contact us',
    budget: 'Request quote',
    name: 'Name',
    email: 'Email',
    alertEmail: 'We will send the answer to this email',
    department: 'Department',
    subject: 'Subject',
    comments: 'Comments',
    send: 'Send',
    product: 'Product',
    amount: 'Qty',
    max: 'Maximum',
    characters: 'caracteres',
    feedbackTitle: 'Message sent successfully!',
    feedbackMessage:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus.'
  },

  /** Detalhes de contato */
  details: {
    commercial: 'Commercial',
    financial: 'Financial',
    phone: 'Phone',
    wpp: 'Whatsapp'
  },

  /** Localização */
  location: {
    title: 'Location',
    address:
      'Rodovia RS 020, 8672 - Parada 125, Caixa Postal 22 Santa Cruz da Concórdia – Taquara/RS CEP 95600-000',
    addressMin: 'Rod RS 020, 8672 - Parada 125, Santa Cruz da Concordia – Taquara/RS'
  },

  /** Rodapé */
  footer: {
    representatives: 'Representatives',
    rights: 'ALL RIGHTS RESERVED'
  },

  /** Erros de endpoint */
  error: {
    'get-categories': 'There was an error displaying the categories.',
    'get-departaments': 'There was an error displaying the departments.',
    'get-representatives': 'There was an error displaying the representatives.',
    'get-products': 'There was an error displaying the products.',
    'contact-email': 'There was an error sending the contact email!',
    'budget-email': 'There was an error sending the quote email!'
  }
}
