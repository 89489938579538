<template>
  <div class="database">
    <table class="datatable--table">
      <!-- COLUNAS -->
      <thead>
        <th v-for="item in cols" :key="item.name">
          <Paragraph size="160" weight="bold" class="datatable--title">{{
            item.alias
          }}</Paragraph>
        </th>
      </thead>
      <tbody>
        <!-- LINHAS -->
        <tr
          v-for="row in rows"
          :key="row.id"
          :class="{
            'datatable--row__separated': row.separated,
            'datatable--row__highlighted': row.highlighted
          }"
          class="datatable--row"
        >
          <td v-for="col in cols" :key="col.name" class="datatable--td">
            <slot :name="col.name" :value="row[col.name]" :row="row">
              <Paragraph
                :class="{
                  'datatable--description__highlighted': row.highlighted
                }"
                size="80"
                weight="bold"
                class="datatable--description"
                >{{ row[col.name] }}</Paragraph
              >
            </slot>
          </td>
          <span v-if="row.separated" class="datatable--separated"></span>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de tabelas */
export default {
  name: 'Datatable',
  components: {
    Paragraph
  },
  props: {
    /** Colunas
     * {
     *  name: 'column',
     *  alias: 'Coluna'
     * }
     */
    cols: {
      type: Array,
      default: () => []
    },
    /** Linhas
     * {
     *  column1: 'Linha',
     *  column2: 'Linha',
     *  column3: 'Linha',
     * }
     */
    rows: {
      type: Array,
      default: () => []
    }
  }
}
</script>

<style lang="scss" scoped>
.datatable {
  &--table {
    background: $white;
    width: 100%;
  }

  &--title {
    color: $primary;
    line-height: 24px;
    padding: 24px;
  }

  &--row {
    position: relative;

    &__separated {
      .datatable--td {
        padding-bottom: 24px;
      }
    }

    &:last-of-type {
      td {
        padding-bottom: 24px;
      }
    }
  }

  &--separated {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    height: 1px;
    background: $primaryOpacity;
  }

  &--td {
    padding: 12px 24px;

    &__separated {
      color: $primary;
    }
  }

  &--description {
    line-height: 24px;

    &__highlighted {
      color: $primary;
    }
  }
}
</style>
