<template>
  <div class="base-layout">
    <!-- INFORMAÇÕES DE CONTATO -->
    <InfoHeader></InfoHeader>
    <!-- CABEÇALHO -->
    <Header></Header>
    <div class="base-layout--content">
      <slot name="content"></slot>
    </div>
    <!-- MAPA -->
    <Maps></Maps>
    <!-- RODAPÉ -->
    <Footer></Footer>
  </div>
</template>

<script>
import InfoHeader from '@/components/_base/InfoHeader'
import Header from '@/components/_base/Header'
import Maps from '@/components/_base/Maps'
import Footer from '@/components/_base/Footer'

export default {
  name: 'BaseLayout',
  components: {
    InfoHeader,
    Header,
    Maps,
    Footer
  }
}
</script>
