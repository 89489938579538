<template>
  <Paragraph :size="size" :weight="weight" class="label">
    <slot> Etiqueta </slot>
  </Paragraph>
</template>

<script>
import Paragraph from '@/components/Paragraph'

/** Componente padrão de etiquetas */
export default {
  name: 'Label',
  components: {
    Paragraph
  },
  props: {
    /** Tamanho */
    size: {
      type: [String, Number],
      default: 80
    },
    /** Negrito */
    weight: {
      type: String,
      default: 'bold'
    }
  }
}
</script>

<style lang="scss" scoped>
.label {
  color: $black;
  border: 1px solid $black;
  border-radius: 5px;
  padding: 8px 15px;
  line-height: 25px;
  display: inline-block;
  margin: 0px 15px 15px 0px;
}
</style>
